.my-infos-container
{
    display: flex ; 
    flex-direction: column;
    padding-bottom: 3rem;
    .data-div {
        // background-color: #ffff;
        min-height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 700px;
        padding: 7.5rem 15px 15px 15px;
        .data-head{
            min-width: 24rem;
            max-width: 32rem;
            min-height: 10rem;
            max-height: 16rem;
            padding-top: 15px;
            display: flex;
            justify-items: center;
            justify-content: center;
            .profile-pic-div{
                // background-image: url('../../assets/images/Mark.png');
                background-size: cover !important;
                background-repeat: no-repeat !important;
                width: 120px !important;
                height: 120px;
                border-radius: 50%;
                position: relative;
                svg{
                    position: absolute;
                    bottom: 0;
                    right: 10px;
                    font-size: 30px;
                    background: #fff;
                    border-radius: 50%;
                    padding: 3px;
                }
            }
        }
        .data-body{
            min-height: 50rem;
            width: 88%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: center;
            padding-top: 4rem;
            .data-body-item{
                display: flex;
                flex-direction: column ;
                gap: 2rem;
                width: 100%; 
                height: 9rem;
                input{
                    width: 100% ;
                    border-radius: 9px ;
                    border: solid 0.5px #00000073; 
                    height: 60%; 
                }
                & > span{
                    font-family: 'Poppins' ;
                    font-size: 1.2rem ;
                    font-weight: 400 ;
                    color: black ;
                }
                .phone-picker{
                    width: 100%;
                    input {
                    height: 55px;
                    width: -webkit-fill-available;
                    border-right: unset;
                    border: 0.5px solid #000000a3;
                    }
                    .react-international-phone-country-selector-button {
                        height: 100%;
                    }
                    div.flag-dropdown{
                        border: 0.5px solid #000000a3;
                        border-right: unset;
                    }
                } 
            }
        }
        .save-btn{
            width: 70% ;
            background-color: #707070;
            border-radius: 25px ;
            font-family: 'Poppins' ;
            font-weight: 500;
            margin-top: 40px;
        }

        .delete-btn{
            width: 70% ;
            background-color: rgb(203, 2, 2);
            border-radius: 25px ;
            font-family: 'Poppins' ;
            font-weight: 500;
            margin: 14px auto;
        }
    }
}
@media only screen and (min-width:600px){
    .data-div{
        width: 70%;
        align-self: center;
    }
}

@media only screen and (min-width:700px){
    .my-infos-container .data-div{ 
        padding-top: 10.5rem;
    }
}