.get-dep-container{
    min-height: 100vh;
    position: relative;
    // background-image: url('../../assets/images/map.png');
    background-repeat: no-repeat ;
    background-size: cover;
    // padding-top: 2rem;
    .contentDep {
        
        position: absolute; /* Set the position of the contentDep to absolute */
        top: 2rem;
        left: 0;
        right: 0;
        bottom: 0;
        height: fit-content;
        // z-index: 5000; /* Set a higher z-index to make it appear above the map */
      }
    #pos-head{
        width: 100% !important;
        display: flex;
        padding-left: 20px;
        justify-content: flex-start;
    }
    .get-dep-head{
        display: flex ;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    svg{
        background-color: white;
        padding: 3px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        align-self: center;
        z-index: 5000;
    }
    .position-div-top {
    min-height: 4rem;
    max-height: 5rem;
    width: 78%;
    background-color: rgb(255 255 255);
    border-radius: 14px;
    display: flex;
    padding: 3%;
    align-items: center;
    gap: 2rem;
        // justify-content: center;
        img{
            min-height: 3rem;
        }
        .spans-div{
            display: flex;
            flex-direction: column;
            overflow: hidden;
            span:first-of-type{
                font-size:1rem;
                font-family: 'Poppins';
                color: gray ;
                font-weight: 400;
            }
            span:nth-of-type(2)
            {
                font-size: 1.3rem;
                font-family: "Poppins";
                color: black;
                font-weight: 700;
            }
            span.adress-text{
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    }
}

#map{
    width: 100%;
    .search{
        // height: 280px;
        div{
            input{
                width: -webkit-fill-available;
                padding: 5px;
                border: 1px solid #db962f;
            }
            button{
                width: 107px;
                border: 1px solid #5c5545;
                background: #5c5545;
                color: #fff;
                padding: auto 4px;
            }
        }
        .listofcities{
            max-height: 250px;
            overflow-y: hidden scroll;
            overflow-x: hidden;
        }
        .cityname span{
            font-size: 0.7rem !important;
        }
    }
    .themap{
        overflow: hidden;
        height: 280px;
    }
}
.leaflet-container {
    height: 100vh;
  }
.leaflet-container img {
    max-height: 100%;
}
.leaflet-container .leaflet-control-attribution{
    display: none;
}
.leaflet-top.leaflet-left{
    left: unset !important;
    right: 0 !important;
    margin-right: 10px !important;
    z-index: 10000;
}

.map-google {
    width: 100%;
    height: 100vh;
    display: flex;

    @media only screen and (max-width: 430px) {
      & {
        height: 100vh;
        margin-top: -5px;
      }
    }

    .map-container {
      flex: 1;
      // height: 500px;
      width: 100%;
      margin: 0 auto;
    }
  }

  .custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .custom-modal {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    .modal-content {
      text-align: center;
  
      h2 {
        color: #6a02c6;
        margin-bottom: 1rem;
      }
  
      p {
        color: #666;
        margin-bottom: 1.5rem;
      }
  
      .cancel-btn {
        background-color: #ff4444;
        color: white;
        border: none;
        padding: 0.8rem 1.5rem;
        border-radius: 8px;
        cursor: pointer;
        font-weight: 500;
        transition: all 0.2s ease;
  
        &:hover {
          background-color: #ff3333;
        }
      }
  
      .loader {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #6a02c6;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
        margin: 0 auto 1rem;
      }
  
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }
  } 