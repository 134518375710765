.comment-comp {
    width: 100%;
    display: flex;
    padding: 5%;
    gap: 22px;
    .comment-head {
        height: 3rem;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
            font-size: 1rem;
        }
        .rate-part {
            height: 50%;
            display: flex;
            width: 15%;
            justify-content: space-between;
            align-items: center;
            max-width: 2.5rem;
            span {
                font-weight: 500;
                font-size: 1.5rem;
            }

            img {
                height: 100%;
            }
        }
    }
    .comment-div {
        width: 80%;
        font-family: 'Poppins',sans-serif;
    border-bottom: solid 0.2px #6b6b6b;
        .comment-body {
            padding-bottom: 1rem;
            p {
                font-size: 0.9rem;
                opacity: 0.6;
                font-weight: 400;
            }
            span{
                padding-top: 1rem;
                font-size: 1rem;
                font-weight: 300;
                a{
                    text-decoration: none;
                    color: #6a02c6;
                }
            }

        }
    }
    .avatar-div {
        width: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1rem;
    }

}
@media only screen and (min-width:900px)  {
    .comment-comp{ 
        padding: 2% !important ;
    }
    .details-container {
        .first-part { 
        height: 59rem ;
    }
}

}

.avatar_pic_comment {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    height: 50px;
    width: 50px;
}