.show-vheic-container { 
    display: flex ;
    flex-direction: column ;
    height: 500px ;
    background-color: white;
    box-shadow: -1px 4px 14px 1px rgba(0, 0, 0, 0.3);
    border-radius: 18px ;
    .image-div{ 
        // background-image: url('../../assets/images/golf.jpeg');
       
    }
    .infos-div{
        display: flex ;
        padding: 3%;
        height: 30%;
        align-items: center;
        justify-content: space-between;
        .speci-side,.price-side{
            display: flex;
            flex-direction: column;
        }
        .speci-side{ 
            gap: 18px;
            span{
                font-family: 'inter',sans-serif ;
                font-weight: 500;
                font-size: 1.2rem;
                color: grey;
            }
            .rate-div{
                display: flex;
                flex-direction: column;
                height: 56px ;
                background-color: white ;
                gap: 4px;
                span{
                    font-size: 1rem; 
                    font-family: 'Poppins';
                }
                .rate-icon{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 39%;
                    width: 59%;
                    padding: 8px;
                    box-shadow: inset 0px -1px 4px 0px #888;
                    border-radius: 13px;
                    img{
                        height: 100%;
                        height: 1rem;
                    }

                }
            }
            .top-speci{ 
                display: flex ;
                flex-direction: column;
                span{
                    font-family: 'Poppins';
                    font-size: 1rem ;
                }
                span:nth-of-type(2)
                {
                    color: #6a02c6;
                }
            }
           
        }
        .price-side { 
            justify-content: center;
            gap: 10px;
            span{
                font-size: 1rem ;
                font-family: 'Poppins',sans-serif;
            }
            span:nth-of-type(2){
                font-size: 1.8rem;
                color: #6a02c6;
                font-weight: bold;
            }
        }
    }
}
.carousel{
    min-height: 73% !important;
    color:black;
    .carousel-inner{
        height: 100%;
    .carousel-item{
        background-position: center;
        background-size: cover;
        min-height: 100% !important;
        border-radius: 17px !important;
        border-radius: 0px 0px 24px 27px;
        -webkit-transition: 0s !important;
        -o-transition: 0s !important;
        transition: 0s !important;
        >span{
            color: white;
            background-color: #00000061;
            font-family: 'Poppins';
            font-size: 0.8rem;
            padding: 5px;
            border-radius: 9px;
            position: absolute;
            top: 1.3rem;
            right: 1rem;
        }
    }
}
.carousel-indicators
{
    margin-left: 38%;
    width: 25%;
    background-color: #8080809e;
    margin-bottom: 2rem !important;
    align-items: center;
    height: 17px;
    border-radius: 25px;
    gap: 5px;
    button{
        width: 1.5rem;
        background-color: #ffff;
    }
    button.active{
        background-color: black;
    }
}
}