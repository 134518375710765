.privacy-comp{
    padding: 10px;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    padding: 7.5rem 5px 5px 10px;
}

@media only screen and (min-width:700px){
    .privacy-comp{ 
        padding-top: 10.5rem;
    }
}