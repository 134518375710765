.sign-in-container{ 
   min-height: 100vh; 
    background-color: #BA6DFE;
    // height: 100vh;
    height: fit-content;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column ;
    align-items: center ;
    // justify-content: space-around;
    z-index: 3;
    padding-top: 5rem;
    padding-bottom: 9rem;
    gap: 2rem;
    >h3{ 
        font-size: 1.9rem ;
        font-family: 'Poppins' ;
        min-height: 5rem;
        font-weight: 500;
        border-bottom: solid 1px ;
        border-bottom-width: 6px;
    }
    form.login-form{
        width: 90%
    }
    .sign-in-body
    {
        width: 100%;
        display: flex;
        min-height: 34rem;
        padding-top: 3rem;
        flex-direction: column;
    max-width: 700px;
        gap: 3rem;
        div.input-part{
            display: flex ;
            flex-direction: column; 
            gap: 2rem;
            min-height: 20%;
        }
        p{
            font-family: 'Poppins' ;
            font-size: 1.46rem ;
            color: white ;
            font-weight: 300;
        }
        input{ 
            width: 100% ;
            min-height: 6rem;
            max-height: 7rem;
            border: none;
            border-radius: 8px;
        }
        .forget-pass{
            margin-top: -22px;
            text-align: right;
            color: #16166d;
            font-weight: 600;
            text-decoration: underline;
        }
    }
    button.cnx-btn{
        background-color: #6A01C6;
        width: 90%;
        border-radius: 26px;
        min-height: 5rem;
        font-family: "Poppins";
        font-weight: 300;
        display: block;
        margin: auto;
    }

    
}
@media only screen and (min-width:700px) {
    .sign-in-container{ 
        padding-top: unset !important;
    }
    form.login-form{
        max-width: 500px;
    }
    .sign-in-body , .sign-in-container>button{ 
        // width: 40% !important ;
        align-self: center;
    }
}