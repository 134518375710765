.notification-container {
  position: relative;
  
  .notification-icon {
    cursor: pointer;
    position: relative;
  }

  .notification-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #ff4444;
    color: white;
    border-radius: 50%;
    padding: 1px 4px;
    font-size: 10px;
    min-width: 16px;
    text-align: center;
  }

  .notification-dropdown {
    position: absolute;
    right: 0;
    top: 35px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    width: 250px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;

    .notification-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;
      border-bottom: 1px solid #eee;

      h3 {
        margin: 0;
        font-size: 14px;
        color: #6A01C6;
      }

      .mark-all-read {
        background: none;
        border: none;
        color: #6A01C6;
        cursor: pointer;
        font-size: 12px;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .notification-item {
      padding: 8px 12px;
      border-bottom: 1px solid #eee;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f5f5f5;
      }

      &.unread {
        background-color: #f0f0ff;
        
        &:hover {
          background-color: #e8e8ff;
        }

        h4::before {
          content: '';
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #6A01C6;
          border-radius: 50%;
          margin-right: 6px;
        }
      }

      .notification-content {
        h4 {
          margin: 0 0 3px 0;
          font-size: 12px;
        }

        p {
          margin: 0 0 3px 0;
          font-size: 11px;
          color: #666;
        }

        .notification-time {
          font-size: 10px;
          color: #999;
        }
      }
    }
  }
} 