.active-request-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.map-container {
  width: 100%;
  height: 100%;
}

.info-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 1000;
}

.back-button {
  background: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  
  &:hover {
    background: #f8f8f8;
  }
}

.status-card {
  background: white;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  h2 {
    color: #6a02c6;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }

  .status-details {
    p {
      margin: 0.5rem 0;
      color: #666;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
} 