$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;
.css-13cymwt-control {
    min-width: 100%;
}
.flag-dropdown {
    border: 0 !important;
}
.react-tel-input .flag-dropdown .selected-flag {
    background-color: white !important;
    border-radius: unset !important;
    padding: unset !important;
    width: unset !important;
    // height: 55px !important;
}
.react-tel-input .flag-dropdown .selected-flag .flag {
    width: 16px !important;
    height: 11px !important;
    margin-left: 8px !important;
}
.css-t3ipsp-control {
    min-width: 100% !important;
    min-height: 100%;
}
.phone-container {
    background-color: #BA6DFE;
    height: 100vh;
    width: 100%;
    margin: 0;
    display: flex;
    z-index: 3;
    .css-b62m3t-container {
        min-width: 100% !important;
    }
    .phone-row {
        width: 100%;
        display: flex;
        align-items: center;
        row-gap: 12rem;
        column-gap: 20%;
        .phone-picker {
            // min-width: 360px !important;
            max-width: 100% !important;
            height: 60px !important;
            input{ 
                height: 100% ;
            }
        }
        .left-side {
            display: flex;
            margin-top: 15px;
            flex-direction: column;
            gap: 3px;
            width: 100%;
            h3 {
                font-size: 1.8rem;
                font-family: 'Poppins',sans-serif;
                font-weight: 700;
            }
            img {
                width: 50px;
                border-top: 0.5px solid #000;
                border-bottom: 0.5px solid #000;
            }
        }
        .right-side {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            // height: 60vh;
            justify-content: space-around !important;
            height: 80vh !important;
            display: flex;
            // justify-content: space-between;
            .css-b62m3t-container,
            input {
                // min-width: 350px;
                // width: 75%;
            }
            .send-btn {
                font-family: 'Poppins';
                font-weight: 300;
                background-color: #6a01c6;
                border-radius: 20px;
                // width: 75%;
                // margin-top: 8%;
            }
            .phone-input {
                height: 60px;

                * {
                    height: 100%;
                }

            }
            p {
                font-size: 1.4rem;
                font-family: 'Poppins',sans-serif;
                color: white;
            }
        }
    }
}
@media screen and (max-width: $breakpoint-sm) {
    .phone-container {
        width: 100%;
        padding: 0;
        flex-direction: column;
        .phone-row {
            // padding: unset 8% 8%;
            width: 100%;
            flex-direction: column !important;
            // gap: 9rem !important;
            // min-height: 58rem;
            .left-side {
                align-items: center;
            }
            .right-side {
                // gap: 13rem;
                max-width: 35rem !important;
                // border: solid;
                // padding: 25px;
                input {
                    // min-width: 250px;
                    width: 100% !important;
                }
                .send-btn {
                    width: 100%;
                    min-width: 250px;
                }

                .accept-div {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 11px;
                    padding-bottom: 33%;
                    p {
                        font-family: 'Poppins';
                        font-size: 1.1rem;
                        margin-top: 7px;
                    }
                }
                .info-case {
                    display: flex;
                    flex-direction: column;
                    gap: 1.4rem;
                    p {
                        padding-left: 5px;
                        font-size: 1.3rem;
                        font-weight: 400;
                        color: #f0f0f0;
                    }
                }
                .phone-picker {
                    border-radius: 25px;
                    min-width: 320px !important;
                    input {
                        // height: 55px;
                    // height: unset !important;
                        // border: 1.5px solid #000000a3;
                    }
                    div.flag-dropdown {
                        border: 1.5px solid #000000a3;
                        border-right: unset;
                    }
                }

            }
        }

    }
}
// @media only screen and (max-width: $breakpoint-md){
    .phone-picker {
        width: 100%;
        input {
            min-height:55px;;
        width: -webkit-fill-available;

        }
    }
    .name-input {
        min-width: 100%;
    }
    .form-control {
        width: 100% !important;
    }
    .phone-container {
        flex-direction: column;
        align-items: center;

        .phone-row {
            display: flex;
            flex-direction: column ;
            width: 50%;
            min-width: 400px;
            row-gap: 2rem;
            .right-side {
                // max-width: 26rem;
                justify-content: center;
                height: 20rem;
            }
            .left-side {
                display: flex;
                margin-top: 15px;
                flex-direction: column;
                gap: 3px;
                width: 100%;
                align-items: center;
            }
        }
    }
    .info-case {
        display: flex;
        flex-direction: column;
        gap: 1.4rem;
        p {
            padding-left: 5px;
            font-size: 1.3rem;
            font-weight: 400;
            color: #f0f0f0;
        }
    }
// }
