@import 'colors';
@import 'fonts';
form{
    .form-label{
        font-family: 'Montserrat-Medium',sans-serif;
        font-size: 16px;
        color:$text-color;
        text-align: left;
        display: block;
        margin-bottom:10px;
    }
    .form-control {
        font-family: 'Montserrat-Medium',sans-serif;
        font-size: 14px;
        border: solid 0.3px $grey4;
        background-color: $grey;
        border-radius: 5px ;
        padding: 12px 14px;
        margin-bottom:20px;
        color: $grey5;
        transition: all 500ms;
        &::placeholder{
            color: $grey5;
        }
        &:focus{
            box-shadow: none;
        }
    }
    .form-check {
        text-align: left;
    }
    .link{
     color:$blue;
      font-family: 'Montserrat-Bold',sans-serif;

    }
}