$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;

.reserv-status-container {
    width: 100%;
    height: 100vh;
    background-color: white;
    gap: 4rem;
    display: flex;
    padding-top: 7.5rem;
    flex-direction: column;
    align-items: center;
    max-width: 700px;

    .pay-btn {
        width: 80%;
        background-color: #6A01C6;
        font-family: 'Poppins';
        font-size: 1.3rem;
        font-weight: 500;
        min-height: 5rem;
    }

    h3 {
        font-family: 'Poppins';
        font-size: 1.7rem;
        margin-left: 2rem;
        align-self: flex-start;
    }

    .reserv-msg-div {
        width: 95%;
        align-self: center;
        // min-height: 38%;
        border: solid 1px #D4CFCF;
        padding: 23px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        border-radius: 15px;
        max-height: fit-content;
        min-height: -moz-fit-content;

        p {
            font-size: 1.3rem;
            font-family: 'Poppins';
            color: black;
            font-weight: 300;
        }

        .end-div {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            gap: 3rem;

            span {
                // font-size: 2rem;
                font-size: 6.5rem;
            
            }
        }

        .docs-par+span {
            color: #6A01C6;
        }

        .docs {
            ul {
                list-style: symbols('-');
            }
        }

        ul {
            list-style: decimal;

            li {
                font-size: 1.2rem;
                font-family: "Poppins";
                color: black;
                font-weight: 600;
                list-style: outside;
                span {
                    color: #BA6DFE;
                }
            }
        }
    }

    .help-div {
        width: 100%;
        height: 20%;
        display: flex;
        padding: 3%;
        flex-direction: column;
        gap: 2rem;

        .help-types {
            display: flex;
            min-height: 1rem;
            height: 20%;
            align-items: center;
            justify-content: flex-start;
            gap: 5rem;
            padding-left: 2rem;

            img {
                height: 100%;
            }
        }
    }

    .purple-part {
        // min-height: 77rem;
        background-color: #f9f3ff;
        padding: 6%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        button {
            background-color: #6A01C6;
            color: white;
            font-family: 'Poppins';
            font-size: 2rem;
            border-radius: 25px;
            width: 100% !important;
        }

        .purple-head {
            display: flex;
            width: 100%;
            justify-content: space-between;

            h3 {
                margin: 0 !important;
            }

            span {
                font-family: 'Poppins';
                font-size: 10px;
                color: black;
            }
        }

        .vh-infos-div {
            display: flex;
            // height: 23%;
            margin-top: 3rem;
            gap: 1rem;
            // max-height: 17rem;

            .img-side {
                width: 45%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                height: 100%;
                border-radius: 15px;
                min-height: 13rem;
            }

            .text-side {
                display: flex;
                flex-direction: column;
                width: 50%;
                gap: 1rem;

                span {
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 300;
                }

                span:nth-of-type(2) {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }

        .total-to-pay {
            // height: 20%;
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 3rem;

            span {
                font-family: 'Poppins';
                color: black;
                font-size: 16px;
                font-weight: 400;
            }

            .price-div {
                display: flex;
                align-items: center;
                gap: 3rem;

                span {
                    font-weight: 500;
                    font-size: 20px;
                    text-decoration: line-through;

                }

                span:nth-of-type(2) {
                    color: #BA6DFE;
                    font-size: 25px;
                    text-decoration: none;
                }
            }
        }

        .days-div {
            height: 35%;
            border-top: 0 !important;

            span:nth-of-type(2),
            span:nth-of-type(3) {
                font-weight: 500;
                font-size: 1.4rem;
            }
        }

        .location-period {
            // border-top: solid 1px rgb(131, 131, 131);
            width: 100%;
            display: flex;
            // height: 13%;
            max-height: 11rem !important;
            gap: 0.5rem;
            flex-direction: column;

            span {
                font-weight: 600;
                font-size: 1.4rem;

            }

            span:first-of-type {
                font-weight: 400;
                font-family: 'Poppins';
                font-size: 1.4rem;

            }
        }

    }
}

@media only screen and (min-width:700px) {
    .reserv-status-container {
        .purple-part {
            // min-height: 100rem !important;

            .vh-infos-div {
                // height: 40% !important;

                .img-side {
                    width: 30%;
                }
            }
        }
    }
}

@media only screen and (min-width:700px){
    .reserv-status-container{ 
        padding-top: 10.5rem;
    }
}

@media only screen and (min-width:900px) {
    .reserv-status-container {
        width: 70%;
        align-self: center;

        button {
            width: 30%;
            align-self: center;
        }
    }

}

.ajouter_title{
    font-size: 1.25rem !important;
    font-weight: 600;
}

.comment_section{
    border: 1px solid #9E9E9E;
    border-radius: 15px;
    padding: 24px;
}

.send_comment{
    width: 100px;
    margin: auto;
    padding: 8px 10px;
    border: 0.5px solid #BA6DFE;
    color: #fff;
    background: #6A01C6;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 10px;
}