.reserv-drawer.css-1u2w381-MuiModal-root-MuiDrawer-root
{
    top: unset !important ;
}
.reserv-drawer {
    width: 100%;
    position: absolute;
    .MuiDrawer-paperAnchorLeft {
        width: 100%;
        // min-height: 40rem;
        // max-height: 45rem ;
        border-radius: 0 ;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    }
    .reserv-container{
        display: flex;
        flex-direction: column;
        padding: 3% 3% 45px 3%;
        gap: 1rem;
        align-items: center;
        height: 100%;
        width: 100%;
        max-width: 700px;
    align-self: center;
        h3{
            font-size: 23px ;
            font-family: 'Poppins'; 
            color: black ;
            margin-top: 18px;
            text-align: center;
        }
        .text-div{
            height: 30%;
            display: flex ; 
            flex-direction: column ;
            gap: 10px ;
            list-style: 4px;
            padding: 1%;
            .contact-div{
                width: 90% ;
                display: flex ;
                gap: 7px; 
                span{
                font-weight: bold;
                }
            }
            p{
                font-size: 14px ;
                font-family: 'Poppins';
                color: black ;
            }
        }
        .btn-div{
            display: flex ;
            flex-direction: column ;
            gap:2rem; 
            margin-top: 1rem;
            width: 100%;
            justify-content: center;
            button{
                margin-top: 0 !important;
                height: 5rem;
                font-family: 'Poppins' ;
                font-size: 15px;
                border-radius: 25px;
                font-weight: 500;
                width: 97%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            button:nth-of-type(2)
            {
                background-color: white ;
                border: solid 1px #6A01C6;
                color: #6A01C6;
            }
        }
    }
}

.reser-text{
    margin-bottom: 10px !important;
}
.location-call{
    display: flex;
    gap: 5px;
    svg{
        font-size: 20px;
        color: green;
    }
    span{
        font-weight: 400;
    }
}

.location-call:first-of-type{
    svg{
        color: #ee2626 !important;
    }
}