$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;
.details-container {
    width: 100%;
    background-color: #f9f3ff;
    min-height: 120vh;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 5rem 0px 5rem 0px;
    gap: 2rem;
    .first-part {
        display: flex;
        // min-height: 79rem;
        height: 100%;
        width: 100%;
        flex-direction: column;
        .vh-photo {
            height: 38rem;
            // background-image: url('../../assets/images/ford.png');
            background-size: cover;
            background-repeat: no-repeat;
        }
        .vh-photo {
            width: 70rem;

        }
        .right {
            // width: 50%;
            height: 100%;
        }
        .right {
            display: flex;
            // width: 50%;
            flex-direction: column;
        }
        .details-title {
            padding: 5%;
            height: 10%;
            display: flex;
            width: 100%;
            align-items: center;
            p {
                font-family: "Poppins";
                font-size: 18px;
                font-weight: 500;
                color: black;
            }
        }
        .details-specif {
            background-color: white;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            padding: 3%;
            p {
                font-family: "Poppins";
                font-size: 1.4rem;
                font-weight: 500;
                color: black;
                padding-top: 4rem;
            }
            table {
                width: 100%;
                height: 20%;
                max-height: 100%;
                tr {
                    font-family: "Poppins";
                    font-size: 1.2rem;
                    font-weight: 200;
                    td:nth-of-type(2) {
                        font-weight: 500 !important;
                        font-size: 1.4rem;
                    }
                }
                tr:not(:first-child) {
                    border-top: 2px solid #e8e8e8;

                }
                td {
                    height: 5rem;
                }
            }
        }
    }
    .second-part {
        // height: 70rem;
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        .description-div {
            background-color: white;
            padding: 3%;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            gap: 15px;
            p {
                font-family: "Poppins";
                font-size: 1.2rem;
                font-weight: 400;
                padding-bottom: 1rem;
                br {
                    margin-bottom: 23px;
                }
            }
            span {
                font-family: "Poppins";
                font-size: 1.5rem;
                font-weight: 500;
                padding-top: 2rem;
            }
        }
        .comment-section {
            width: 100%;
            padding: 2%;
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 5rem !important;
            padding-top: 5rem !important;
            .comment-section-head {
                width: 100%;
                display: flex;
                justify-content: space-between;
                height: 3rem;
                .rate-icon {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 59%;
                    width: 15%;
                    max-width: 90px;
                    min-width: 80px;
                    padding: 10px;
                    box-shadow: inset 0 1px 4px 0 #888;
                    border-radius: 13px;
                    img {
                        height: 100%;
                        height: 1rem;
                    }

                }
                > span {
                    font-family: 'Poppins';
                    font-size: 1.7rem;
                    font-weight: 500;
                    padding-left: 16px;
                }
                img {
                    height: 100%;
                    width: 40%;
                    object-fit: contain;
                }
            }
            .show-all-btn {
                background-color: #ffff;
                color: #6a02c6;
                font-family: 'Poppins';
                font-size: 1.2rem;
                font-weight: 500;
                border: solid 1px #6a02c6;
                width: 80%;
                height: 4rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }
    }

}
@media only screen and (max-width : $breakpoint-sm) {
    .details-container {
        align-items: center;
        .first-part {
            flex-direction: column;
            // height: 80%;
            .right,
            .vh-photo {
                width: 100%;

            }
            .vh-photo {
                height: 38rem;
            }

        }
    }
}
@media only screen and (min-width:950px) {
    .details-container {
        // padding-top: 5rem;
        .vh-photo {
            height: 55rem !important;
        }
    }
}

@media only screen and (min-width:700px){
    .reservation-container{ 
        padding-top: 10.5rem;
    }
}

.show-vheic-container2{
    display: flex ;
    flex-direction: column ;
    height: 380px ;

    .carousel2{
        min-height: unset !important;
        height: inherit;
        .carousel-item{
            border-radius: unset !important;
            border-radius: unset !important;
        }
    }
}