.over-vhei-res{
    height: fit-content;
    background-color: #FFFFFF;
    filter: drop-shadow(0px 10px 3px rgba(0,0,0,0.16 ));
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding-bottom: 10px;
    border-radius: 8px;

    div.finaliser-res{
        padding: 12px 10px;
    background: #e80000;
    color: #fff;
    width: 90%;
    text-align: center;
    font-weight: 600;
    border-radius: 5px;
    font-size: 1.25rem;
    }
}

.vhei-reserv-comp{
    width: 100% ;
    display: flex ;
    min-height: 9rem;
    border-radius: 7px;
    padding: 2%;
    gap: 1rem;
    .img-div{
        max-width: 11rem;
    min-width: 10rem;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    background-position: center;
    }
    .state-div{
        display: flex ;
        flex-direction: column ;
        gap: 0rem;
        width: 55%;
        // min-width: 23rem;
        padding-top: 4px;
        span{
            font-family: 'Poppins', sans-serif;
            color: gray ;
            font-size: 1rem;
        }
        span:first-of-type{
            font-size: 1.5rem ;
            font-weight: 600 ;
            color: black;
        }
        span.accepte-except1{
            font-size: 1.2rem !important;
            font-weight: 500 !important;
            color: green !important;
        }
    }
    .angle{
        align-self: center;
        justify-self: center;
        justify-self: end;
        max-width: 26px;
    }
}

