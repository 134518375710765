.all-reserv-container{
    min-height: 100vh ;
    background-color: #f1f1f1 ;
    padding: 7.5rem 15px 15px 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    gap: 5rem;
    .tab-nav-div{
        width: 100% ;
        background-color: #e9e9e9;
        display: flex ;
        height: 6rem;
        max-height: 7rem !important;
        padding: 5%;
        justify-content: center;
        border-radius: 25px;
        align-items: center;
        button{
            font-family: 'Poppins';
            font-size: 1.2rem;
            font-weight: 700;
            color: grey;
            width: 50%;
            max-height: 4rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 40px;
        }
      #activebtn{ 
            background-color: #6A01C6 ;
            color:white
        }
    }
    .all-res-body{
        display: flex; 
        flex-direction: column;
        gap: 3.5rem;
        & > div{
            width: 100%;
            display: flex ;
            flex-direction: column;
            gap: 1rem;
            & > span{
                font-family: 'poppins' ,sans-serif ;
                color: gray ;
                font-weight:300 !important;
                font-size: 1.1rem;
            }
        }
       
    }
}
@media only screen and (min-width:700px)  {
    .tab-nav-div
    {
        padding: 1% !important;
    }
    .all-res-body{ 
        display: grid !important;
        grid-template-columns: repeat(2,45%);
        column-gap: 9% !important;
    }
    
}

@media only screen and (min-width:700px){
    .all-reserv-container{ 
        padding-top: 10.5rem;
    }
}