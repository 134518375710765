.nav-header {
width: 100% ; 
display: flex ;
padding: 3%;
button{
    padding: 4px;
    border: unset;
    margin: 0;
    background: #fff;
    border-radius: 50%;

    svg { 
        background-color: #ffff ;
        border-radius: 50% ;
        border: none ;
        width: 20px;
        height: 20px;
        font-size: 12px;
    }
}
}