@import "../../assets/sass/colors";
@import url( 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500&display=swap');
.navbar-app {
    font-family: 'inter',sans-serif;
    height: 8rem;
    background-color: #7F11E0 !important;
    color: white !important;
    width: 100%;
    position: absolute !important;
    z-index: 1000;
    .log-app {
        color: white;
        font-weight: 500;
        width: 10%;
        img {
            object-fit: contain;
            min-width: 185px;
        }
    }
    .profile-sec {
        display: flex;
        flex-direction: column;
        align-items: center;
        .profile-infos {
            font-size: 18px;
            font-weight: 200;
        }
    }
    .page-descriptor {
        font-size: 20px;
        font-weight: 300;
    }
    .header-actions {
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 20px;
    }
}
.MuiBox-root {
    .mobile-header {
        background-color: white;
        font-family: 'inter',sans-serif;
        font-weight: 300;
        color: black;
        padding: 0 !important;
        // position: fixed;
        .mobile-header-inner {
            justify-content: space-between;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
        z-index: 2;
            span{ 
                color: grey ;
                font-family: 'inter',sans-serif ;
                font-weight: 500;
            }
            button {
                font-size: 12px;
            }
        }

    }
}
