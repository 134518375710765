.select-container{
    // min-height: 100vh;
    background: white ;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4em;
    width: 100%;
    max-width: 700px;
    gap: 4rem;
    h3{
        font-family: 'Poppins',sans-serif ;
        font-size: 2rem ;
        font-weight: 358;
        height: 5rem;
        border-width: 15px;
        display: flex;
        flex-direction: column;
        font-weight: 400; 
        gap: 2.5rem;
        .line{
            min-height: 3px;
            border-bottom: solid 5px black;
            width: 43%;
            align-self: center;
          }
    }
 
    .select-div{
        height: 50%;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 5rem;
        min-height: 30rem;
        justify-content: center;
        >span{
            font-family: 'inter',sans-serif;
            font-size: 1.5rem;
            opacity: 0.6;
            font-weight: 300;
        }
        .select-element{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 11rem;
            padding: 0 20px;
            width: 100%;
            input{
                height: 2rem;
                width: 2rem;
            }
            p{
                font-family: 'Poppins';
                color: black;
                width: fit-content;
                font-size: 1.78rem !important;
                font-weight: 400;
                width: 80% ; 
                word-spacing: 4px;
            
            }
        }
        }
        .camion-div{
            height: 30% ;
            gap: 2rem; 
            width: 81%;
            margin-top: 1rem;
            span{
                margin-bottom: 2rem;
                word-spacing: 2px;
                font-size: 1.6rem;
            }
            .select-element{
                width: 100%;
                gap: 3rem;
            justify-content: flex-start !important;
                p{
                    font-size: 1.5rem  !important;
                    font-weight: 400 ;
                }
            }
        }
        .ok-btn{
            background-color: #6a01c6;
            color: white;
            font-family: 'Poppins';
            font-size: 1.5rem;
            width: 58%;
            border-radius: 25px;
        }
        .bottom-g{
            background-image: url('../../assets/images/pattern.png');
            background-size: cover;
            width: 100%;
            height: 10rem;
        }
}