// .swipe-comp{
//     
//     height: 47rem ;
//     min-width: 100%;
//     display: flex ;
//     align-items: center;
//     min-height: 30rem;
//     position: fixed ;
//     z-index: 1;
//     bottom: 0;
//     width: 370rem;
//     overflow-x:scroll;
//     white-space:nowrap;
// }
.container-swipe {
    z-index: 5000;
    background-color: rgba(0, 0, 0, 0.2588235294);
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    width: -webkit-fill-available;
    overflow-x: scroll;
    overflow-y: auto;
    white-space: nowrap;
    padding: 20px 0;
    .mini-cont {
      height: 90%;
      width: 100%;
      display: inline-block; /* Make the content inline to scroll horizontally */
      white-space: nowrap; /* Prevent content from wrapping */
      font-size: 0;
    }
  }
  
   
  