.vhmarque-drawer{

    .MuiDrawer-paperAnchorLeft {
        height: 76% !important;
        position: absolute !important;
        // top: 15% !important;
        width: 100%;
        border-radius: 15px;
    } 
    .rate-head {
        display: flex;
        min-height: 7rem;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        height: 10%;
        margin-bottom: auto;
        position: sticky;
        top: 0;
        background: white;
        button{
            height: 100%;
            max-width: 5rem;
            width: 12%;
            border-radius: 50px;
            margin: 0;
            font-size: 2rem;
            background-color: white;
            font-weight: 500;
            filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.16 ));
            color: blueviolet;
            display: flex;
            align-items: center;
        }
        span{
            font-size: 1.5rem;
            font-family: 'Poppins';
            font-weight: 400;
        }
    }
    .vhmarque-body{
        display: flex ;
        flex-direction: column ;
        padding: 15px ;
        height: 84%;
        overflow: hidden scroll;
        .select-option {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 6rem ;
            span {
                font-family: 'Poppins', sans-serif;
                color: black;
                font-weight: 500;
                font-size: 1.8rem;
                width: 60% ;
            }
            button {
                width: 68px;
                height: 20px;
                padding: 6px;
                font-family: 'Poppins' ;
                font-size: 0.8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #7f11e0;
                margin: 0 !important;
            }
        }
    }
}