.done-container{
    min-height: 100vh ;
    display: flex;
    flex-direction: column ;
    padding-top: 12rem ;
    gap: 6rem;
    align-items: center;
    h3{
        font-family: 'Poppins';
        font-size: 2.6rem ;
    }
.msg-div{
    width: 95%;
    align-self: center;
    min-height: 38%;
    border: solid 1px #D4CFCF;
    padding: 23px;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    border-radius: 15px;
    max-height:20rem ;
    min-height: 11rem;
    p {
        font-size: 1.5rem;
        font-family: 'Poppins';
        color: black;
        font-weight: 400;
        span{
            font-weight: bold;
        }
    }

    .end-div{
        height: 100%;
        width: 100% ;
        display: flex ;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        gap: 3rem;
        span {
            font-size: 2rem;
        }
    }
}
.buttons-div{
    min-height: 25rem ;
    display: flex ;
    flex-direction: column ;
    width: 90% ;
    gap: 4rem;
    button{
        width: 100% ;
        font-family: 'Poppins' ;
        font-size: 1.8rem;
        font-weight: 400;
        min-height: 6rem;
        color: white;
        background-color: #6A01C6 ;
        border-radius: 27px ;
    }
    button:nth-of-type(2){
        background-color: white;
        border: solid 1px #6A01C6 ;
        color: #6A01C6 ;
        font-size: 1.7rem ;
        font-weight: 550;
    }
}
   
}
@media only screen and (min-width:900px){
    .done-container{
        width: 60%;
        align-self: center;
    } 
    .buttons-div{ 
        flex-direction:row !important;
        button{ 
            width: 10% ;
            max-height: 5rem;
        }
    }
}
