.position-swipe {
    min-height: 18rem;
    width: 100%;
    background-color: #00000036;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:2rem;
    z-index: 5000;
    .position-div {
        min-height: 2rem;
        max-height: 5rem;
        width: 93%;
        background-color: white;
        border-radius: 25px;
        display: flex;
        padding: 15px;
        align-items: center;
        gap: 3rem;
        // justify-content: center;
        img{
            height: 70%;
            min-height: 2rem;
        }
        .spans-div{
            display: flex;
            flex-direction: column;
            overflow: hidden;
            span:first-of-type{
                font-size: 1.2rem;
                font-family: 'Poppins';
                color: gray ;
                font-weight: 400;
            }
            span:nth-of-type(2)
            {
                font-size: 1.5rem;
                font-family: 'Poppins';
                color: black;
                font-weight: bold;
            }
            span.adress-text{
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

    }
    button{
        width: 69%;
        background-color: #6a01c6;
        font-family: "Poppins";
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4.3rem;
        border-radius: 25px;
    }
}

@media screen and (min-width: 768px) {
    .position-swipe .position-div{
        width: 60%;
    }
    .position-swipe button{
        width: 40%;
    }
}