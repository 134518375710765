.total-stripe-container{
   height: 20% ;
   min-height: 20rem;
   background-color:#F9F3FF;
    width: 100%;
    display: flex ;
    padding: 14px ;
     gap: 1rem ;
    .left-side{ 
        height: 100%;
        width: 57%;
        display: flex ;
        flex-direction: column ;
        gap: 0.5rem;
        p{ 
            font-family: 'Poppins',sans-serif ;
            font-size: 1.5rem !important;
            font-weight: 400 !important;
        }
        span
        {   font-family: 'Poppins',sans-serif ;
            font-size: 1.7rem;
            font-weight: 500;
        }
        p+span{ 
            font-weight:bold;
            font-size: 1.9rem; 
        }
        p:last-of-type{
            color: grey ;
            margin-top: 18px ;
            font-size: 1rem !important;
            span{
                font-size: 1.1rem;
                font-weight: 300;
                color:rgb(185, 137, 229) ;
            }
        }
    }
    .right-side {
        display: flex ;
        flex-direction: column;
        width: 41%;
        justify-content: center;
        span:first-of-type{
            font-family: 'Poppins';
            font-size: 1.8rem;
        }
        span:nth-of-type(2) 
        { 
            font-family: 'Poppins';
            font-weight: bold;
            font-size: 2.2rem ;
            color: #6A01C6;
        }
    }
}