$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;
.auth-container {
    background-color: #E7CCFF;
    border-radius: 5px;
    width: 100% !important;
    height: 100vh;
    overflow-x: hidden;
    .auth-banner {
        width: 100%;
        height: 50%;
        background-image: url("../../assets/images/Kleever-auth-banner.png");
        background-size: cover;
    }
    .auth-control {
        width: 100%;
        font-family: 'inter',sans-serif;
        height: 45%;
        padding: 5%;
        .side-decoration {
            width: 100%;
            z-index: 2;
            .left-side {
                background-color: white;
                clip-path: ellipse(13% 20% at 0% 50%);
            }
        }
        .connexion-btns {
            width: 100%;
            .btn-div {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-top: 80px;
                gap: 50px;
                button {
                    width: 75%;
                    border-radius: 14px;
                }
                button:first-of-type {
                    background-color: #7F11E0;
                    border: none;
                    font-weight: 500;
                    font-family: inherit;
                }
                button:nth-of-type(2) {
                    background-color: transparent;
                    border: solid 1px #7F11E0;
                    color: black;
                    font-weight: 500;
                    font-family: inherit;
                }
                button:hover {
                    box-shadow: inherit;
                    transform: inherit;
                }
            }

            .text-div {
                display: flex;
                flex-direction: column;
                gap: 3%;
                height: auto;
                min-width: 100%;
                margin-left: -7px;
                align-items: center;
                img {
                    height: 5px;
                    max-width: 8%;
                    min-width: 40px;
                    object-fit: contain;
                }
                h3 {
                    font-size: 26px !important;
                }
            }

        }
    }
}
@media screen and (max-width: $breakpoint-sm) {
    .auth-container {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 60px;
        .auth-banner {
            min-height: 30%;
            max-height: 35%;
        }
        .auth-control {
            padding: 0;
            .connexion-btns {
                height: 100%;
                gap: 35px;
            }

            .text-div h3::after,
            .text-div h3::before {
                content: " ";
                position: absolute;
                width: 100px;
                height: 100px;
                background-color: white;
                /* Change this to the desired color */
                border-radius: 50%;
            }

            .text-div h3::before {
                left: -20px;
                top: 0;
                box-shadow: inset -40px 0 0 1px #e7ccff;
            }

            .text-div h3::after {
                right: -78px;
                top: 0;
                box-shadow: inset -40px 0 0 1px #e7ccff;
            }
            .text-div h3 {
                padding-top: 20px;
            }
        }

    }
}
@media screen and (min-width: 600px) {
    .text-div h3::after,
    .text-div h3::before {
        width: 50px;
        height: 50px;
    }
    .btn-div{ 
        button {
            width: 20%;
            max-width: 30rem;
        }
    }

}
