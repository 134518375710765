$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;
.show-offre-container {
    min-height: 100vh;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    padding: 7.5rem 15px 15px 15px;
    gap: 2rem;
    >span{
        font-size: 1.3rem; 
        opacity: 0.6; 
        font-family: 'Poppins';
        align-self: flex-end;
    }
    .desktop-top {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .offre-head {
            height: 15rem;
            display: flex;
            gap: 1rem;
            .img-div {
                // background-image: url('../../assets/images/kia.png');
                max-width: 20rem;
                min-width: 13rem;
                height: 13rem;
                border-radius: 25px;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .infos-div {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 1%;
                width: 60%;
                span {
                    font-family: 'Poppins';
                    color: black;
                    font-size: 1.7rem;
                    font-weight: 300;
                }
                span:nth-of-type(2) {
                    font-size: 1.8rem;
                    font-weight: 500;
                }

            }
        }
        .total-case {
            border: solid 0.5px gray;
            padding: 5%;
            border-radius: 25px;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            p {
                font-family: 'Poppins';
                font-size: 1.8rem;
                color: black;
                font-weight: 300;
                span {
                    font-weight: 400;
                }
            }
            p:nth-of-type(2) {
                font-weight: 500;
            }
            .price-div {
                display: flex;
                flex-direction: column;
                height: 16rem;
                justify-content: flex-end;
                gap: 1rem;
                span {
                    font-family: 'Poppins';
                    font-size: 1.8rem;
                }
                span:nth-of-type(2) {
                    font-size: 3rem;
                    color: #6A01C6;
                }
            }
        }
        .btn-div {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            button {
                width: 70%;
                background-color: red;
                color: white;
                border-radius: 30px;
                font-size: 2rem;
                font-family: 'Poppins';
            }
            button:first-of-type {
                background-color: #00811A;
            }
        }
    }
}
@media only screen and (min-width:$breakpoint-md) {
    .btn-div {
        display: flex;
        flex-direction: row !important;
        justify-content: center;
        button {
            width: 30% !important;
        }
    }
    .btnterm {
        width: 30% !important;
    }
}

.btnterm {
    width: 70%;
    background-color: #6A01C6;
    color: white;
    border-radius: 30px;
    font-size: 2rem;
    font-family: 'Poppins';
    text-align: center;
    margin: auto;
    padding: 10px 2px;
}

@media only screen and (min-width:700px){
    .show-offre-container {
        padding-top: 10.5rem;
    }
}