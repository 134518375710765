$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;
.my-pubs-page{
    min-height: 100vh ;
    background-color: #f1f1f1 ;
    padding: 8rem 15px 15px 15px;
    display: flex ;
    flex-direction: column;
    gap: 3.5rem;
    .input-group { 
        flex-wrap: nowrap;
        border-radius: 55px;
        .input-group-prepend
        {
            .search-icon { 
                padding: 1rem;
                display: flex;
                align-items: center;
                width: 4rem;
                height: 3.5rem;
                background-color: white;
                border-radius: 28px 4px 0px 28px;
                svg{
                    font-size: 1rem;
                    width: 18px;
                    opacity: 0.6;
                }
            }
        }
        input {
            border: none !important;
            border-radius: 0px 28px 28px 0px  !important;
            height: 3.5rem;
        }
        input::placeholder {
        opacity: 0.6 !important;
        text-align: center;
        }

    }
    .pubs-grid{
        display: grid; 
        grid-template-columns: 100% ;
        gap: 2rem;
        justify-content: center;
        .my-pubs-comp
        {
            width: 100% ;
            display: flex ;
        }
    }
}
@media only screen and (min-width:900px) 
{
    .pubs-grid{
        grid-template-columns: repeat(2,45%) !important;
        column-gap: 6rem !important;
    }
}

.pagination_section{
    margin-left: auto;
}

@media only screen and (min-width:700px){
    .my-pubs-page{ 
        padding-top: 10.5rem;
    }
}