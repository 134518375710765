.stepp-box{ 
circle{ 
    color: #E6E6E6 ;
    border: solid 1px #707070;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed , .Mui-completed 
{
color: #02b431 !important; //#37F86A
}
.css-qivjh0-MuiStepLabel-label{ 
    font-size: 0.63rem;
    font-family: 'Poppins';
    font-weight: bold !important;
}
}