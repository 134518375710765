.mes-offres-container{
    min-height: 100vh ;
    width: 100%;
    background-color: #f1f1f1;
    padding: 7.5rem 15px 15px 15px;
    display: flex;
    // max-width: 700px;
    flex-direction: column;
    gap: 2rem;
    .offre-item{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        span{
            font-family: 'Poppinss',sans-serif ;
            color: gray ;
            font-family: 300;
        }
    }
}

@media only screen and (min-width:700px){
    .mes-offres-container{ 
        display: grid;
        padding-top: 10.5rem;
        grid-template-columns: repeat(2,50%);
    }
}
