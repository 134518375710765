.depp-info-page {
    min-height: 100vh;
    background-color: #6A01C6;
    display: flex;
    flex-direction: column;
    .profile-depp-head {
        width: 100%;
        display: flex;
        height: 10.5rem;
        padding: 15px 10px 10px;
        .kleever-log {
            background-image: url('../../assets/images/kleevermainlogo1.png');
            width: 23%;
            background-repeat: no-repeat;
            background-size: contain;
            min-height: 100%;
        }
    }
    .data-div {
        background-color: #ffff;
        min-height: 90vh;
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 9rem;
        .data-head{
            width: 100%;
            min-height: 18rem; 
            padding-top: 15px;
            display: flex;
           .left-side{
            display: flex ;
            flex-direction: column ;
            justify-content: flex-start;
            width: 33%;
            padding: 10px;
            .back-control{
                height: 10% ;
                display: flex;
                gap: 1rem;
                align-items: center;
                span{
                    font-family: 'Poppins' ;
                    font-size: 1.3rem ;
                    font-weight: 300; 
                }
            }
           }
           .right-side
           {
            width: 70% ;
            display: flex;
            justify-content:flex-start ;
            .profile-pic-div{
                // background-image: url('../../assets/images/deppavatar.png');
                // background-size: contain;
                // width: 58%;
                // height: 100%;
                // background-repeat: no-repeat;
                // align-self: center;
                background-size: cover !important;
                background-repeat: no-repeat !important;
                width: 120px !important;
                height: 120px;
                border-radius: 50%;
                svg{
                    position: relative;
                    top:80%;
                    left: 74% !important;
                    font-size: 30px;
                    background: #fff;
                    border-radius: 50%;
                    padding: 3px;
                }
            }
           }
        }
        .data-body{
            min-height: 50rem;
            width: 88%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: center;
            padding-top: 4rem;
            .data-body-item{
                display: flex;
                flex-direction: column ;
                gap: 2rem;
                width: 100%; 
                height: 9rem;
                input{
                    width: 100% ;
                    border-radius: 9px ;
                    border: solid 0.5px #00000073; 
                    height: 60%; 
                }
               & > span{
                    font-family: 'Poppins' ;
                    font-size: 1.3rem ;
                    font-weight: 500 ;
                    color: black ;
                }
                .phone-picker{
                    width: 100%;
                    input {
                    height: 55px;
                    width: -webkit-fill-available;
                    border-right: unset;
                    border: 0.5px solid #000000a3;
                }
                .react-international-phone-country-selector-button {
                    height: 100%;
                }
                div.flag-dropdown{
                    border: 0.5px solid #000000a3;
                    border-right: unset;
                }
                } 
            }
        }
        .save-btn{
            width: 70% ;
            background-color: #707070;
            border-radius: 25px ;
            font-family: 'Poppins' ;
            font-weight: 500;

        }
    }

}
@media only screen and (min-width:700px){
    .data-head{
        gap: 11rem;
    }
    .profile-pic-div{
        background-image: url('../../assets/images/deppavatar.png');
        background-size: contain;
        width: 20% !important; 
        height: 100%;
        background-repeat: no-repeat;
        align-self: center;
        svg{
            position: relative;
            top:70%;
            left: 80%;
        }
    }
}