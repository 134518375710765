.mobile-header{ 
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 5;
    padding-left: 7px;
    padding-right: 1px;
    padding-left: 14px !important;
    padding-right: 5px !important;
    .mobile-header-inner{
        padding-left: 7px !important ;
    padding-right: 1px !important ;
    }
    span {
        font-family: 'Poppins';
        font-size: 1.3rem !important;
        font-weight: 500;
    }
   
}

.mobile-header1{
    position: fixed;
    width: 100%;
    z-index: 1000;
    margin-top: -2px;
}