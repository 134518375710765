.rate-drawer{
    .MuiDrawer-paperAnchorLeft {
        height: 86% !important;
        position: relative !important;
        top: 15% !important;
        width: 100%;
        border-radius: 15px;
        .rate-container{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 3rem;
            .rate-head{
                display: flex;
                min-height: 7rem;
                align-items: center;
                justify-content: space-between;
                padding: 3%;
                height: 10%;
                button{
                    margin: 0;
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    font-size: 1.8rem;
                    background-color: white;
                    font-weight: 500;
                    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
                    color: blueviolet;
                    display: flex;
                    align-items: center;
                    padding: 11px;
                }
                span{
                    font-size: 2rem;
                    font-family: 'Poppins';
                    font-weight: 500;
                }
            }
            .comment-cont{
                width: 100%; 
                display: flex;
                flex-direction: column;
                min-height: 90rem;
               
            }
            .give-rate-div{
                min-height: 20rem; 
                height: 20% ;
                display: flex;
                flex-direction: column;
                padding: 3%;
                span{
                    color:blueviolet  ;
                    font-family: 'Poppins';
                    font-size: 2rem;
                    font-weight: 500;
                }
                button{
                    background-color: white;
                    border: solid 1px blueviolet;
                    color: blueviolet;
                }
            }
        }
    }
}