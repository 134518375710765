.deppaneur-card {
    height: fit-content;
    background: white;
    margin-left: 3rem;
    padding: 1rem;
    border-radius: 20px;
    display: inline-block;
    min-width: 290px;
    .depp-btn {
        background-color: green;
        color: white;
        font-size: 13px;
        border-radius: 12px;
    }
    .depp-card-body {
        height: 100%;
        display: flex;
        gap: 3.5rem;
        flex-direction: column;
        padding-bottom: 25px;
        .middle-card {
            height: 45%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 36%;
            }
            > span {
                font-family: "Poppins";
                font-size: 2.5rem;
                font-weight: 500;
            }
        }
        .depp-card-head {
            display: flex;
            padding: 5px;
            gap: 1rem;
            align-items: flex-start;
            #dep-img {
                width: 55px;
                height: 55px;
                border-radius: 50%;
                object-fit: cover;
                background-position: center;
            }
            .infos-div {
                width: 70%;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                > span {
                    font-family: "Poppins";
                    font-size: 1.4rem;
                    color: #6A01C6;
                    font-weight: bold;
                }
                a {
                    font-size: 0.9rem;
                    font-family: "Poppins";
                    text-decoration: underline;
                    font-weight: 400;
                    color: #6A01C6;
                }
                .rate-part {
                    background-color: white;
                    height: 25%;
                    display: flex;
                    padding: 5px;
                    border-radius: 25px;
                    width: 60px;
                    justify-content: space-around;
                    align-items: center;
                    max-width: 8rem;
                    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
                    box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.16);
                    span {
                        font-size: 1rem;
                        font-weight: 500;
                        color: black;
                    }

                    img {
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                        width: 10px;
                    }
                }
            }
        }
    }
}

.button-group {
    display: flex;
    gap: 10px;
    width: 100%;
}

.depp-btn, .choose-btn {
    flex: 1;
}

