.offre-comp{
    width: 100% ;
    display: flex ;
    min-height: 11rem;
    height: fit-content;
    background-color: #FFFFFF;
    filter: drop-shadow(0px 10px 3px rgba(0,0,0,0.16 ));
    border-radius: 10px;
    padding: 3%;
    gap: 2rem;
    .img-div{
        max-width: 11rem;
        min-width: 10rem;
        // background-image: url('../../assets/images/nissan.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 20px;
    }
    .state-div{
        display: flex ;
        flex-direction: column ;
        gap: 1.2rem;
        width: 50%;
        min-width: 25rem;
        span{
            font-family: 'Poppins' ;
            color: gray ;
            font-size: 1rem;
        }
        span:first-of-type{
            font-size: 1.5rem ;
            font-weight: 500 ;
            color: black;
              }
    }
    .angle{
        align-self: center;
        justify-self: end;
    }
}
@media only screen and (min-width:700px){
    .offre-comp{
        .state-div{ 
            min-width: 56rem;
        }
    } 
}