$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;
.reservation-container {
    background-color: #f9f3ff;
    max-height: fit-content;
    height: 100vh;
    display: flex;
    max-width: 700px;
    flex-direction: column;
    gap: 1rem;
    padding: 5rem 0px 3rem 0px;
    .top-div {
        display: flex;
        padding: 7px;
        flex-wrap: wrap;
        min-height: 15rem;
        width: 100%;
        gap: 12px;
        background-color: #fff;
        align-items: center;
        align-content: center;
        padding: 20px 0 10px 2rem;
        z-index: 1;
        .infos-div {
            display: flex;
            flex-direction: column;
            width: 19rem;
            align-self: flex-start;
            gap: 7px;
            padding-top: 2px;
            span {
                font-family: 'Poppins';
                font-weight: 400;
            }
            span:first-of-type {
                font-size: 1.45rem !important;
                font-weight: 400;
            }
            span:nth-of-type(2) {
                font-size: 1.8rem;
                font-weight: 600;
            }
            span:nth-of-type(3) {
                color: #a65be8;
                border: solid 1px #a65be8;
                padding: 1rem;
                font-weight: 400;
                font-size: 1rem;
                text-align: center;
                border-radius: 3px;
                margin-top: 7px;
                width: 19rem;
            }
        }
        .reserv-pic {
            width: 13rem;
            background-repeat: no-repeat;
            align-self: flex-start;
            background-size: cover;
            height: 13rem;
            border-radius: 15px;
        }
    }
    .middle-div {
        display: flex;
        flex-direction: column;
        height: 70%;
        gap: 25px;
        background-color: white;
        p {
            font-family: 'Poppins';
            font-size: 1.4rem !important;
            font-weight: 400;
            color: black;

        }
        .date-part {
            display: flex;
            flex-direction: column;
            padding: 13px;
            height: 14rem;
            gap: 2rem;
            padding: 41px 17px 0 15px;
            p {
                font-family: 'Poppins';
                font-size: 1.4rem;
                font-weight: 400;
                color: black;
            }
            .select-date {
                display: flex;
                width: 100%;
                border: solid 0.5px #505050;
                border-radius: 8px;
                height: 5rem;
                background-color: white;
                align-items: center;
                .date-div {
                    width: 50%;
                    display: flex;
                    height: 7rem;
                    align-items: center;
                    // gap: 7px;
                    height: 100% !important;
                    span {
                        height: fit-content;
                        align-self: center;
                        text-align: center;
                        text-align: center;
                        font-size: 1.56rem;
                        font-family: 'Poppins',sans-serif;
                        font-weight: 400;
                    }
                    input[type="date"] {
                        background-color: transparent;
                        width: 55%;
                        border: none;
                        height: 80%;
                    }
                    input[type="date"]::-webkit-calendar-picker-indicator
                    // input[type="date"]::-webkit-datetime-edit-text,
                    {
                       opacity: 0;
                    //    z-index: -2;
                       position: absolute ; 
                       width: 10rem;
                    // max-height: 0.2px;
                       
                    }
                    input[type="date"]::placeholder {
                        // display: none;
                    }
                    img {
                        width: 25%;
                        padding: 11px;
                        border-radius: 26px;
                        height: 97%;
                        object-fit: contain;
                    }
                }
            }
        }
        .date-div:first-of-type{
            border-right: solid 0.5px #505050;
        }
        .total-div {
            display: flex;
            flex-direction: column;
            gap: 4rem;
            .total-days {
                padding: 14px;
                display: flex;
                gap: 1rem;
                flex-direction: column;
                p,
                span {
                    font-size: 1.4rem;
                    font-weight: 350;
                    font-family: 'Poppins' ,sans-serif;
                    color: black;
                }
                span {
                    font-size: 1.8rem;
                    font-weight: 500;
                }
            }
        }
        .confirme-btn {
            width: 88%;
            background-color: #6a02c6;
            color: white;
            border-radius: 25px;
            font-size: 1.6rem;
            font-family: 'Poppins';
            font-weight: 300;
            align-self: center;
            height: 11%;
            max-height: 5rem ;
            margin-bottom: 2rem;
            margin-top: auto;
        }
    }
}
input[type="date"]::-webkit-calendar-picker-indicator {
    // opacity: 0;
    background-color: red;
}
@media only screen and (min-width:700px ) {
    .date-part{
     display: grid !important;
    align-items: center;
    grid-template-columns: repeat(2,46%);
    }
    .top-div {
        height: fit-content;
        .infos-div {
            flex-direction: column !important;
            justify-content: center !important;
            width: 100%;
            span {
                font-size: 1.5rem ;
            }
        }
        .reserv-pic {
            height: 45rem;
            width: 45rem ;
            object-fit: contain !important;
            border: none;
            align-self: center;

        }
    }
    .reservation-container {
        height: fit-content;
        gap: 3rem;
        width: 80%;
        align-self: center;
        // padding-top: 19rem; 
       button{ 
          width: 60%;
        //    max-height: 5rem;
        }
       
    }
}

@media only screen and (min-width: 700px){
    .reservation-container{
        padding-top: 7rem;
    }
}