.MuiDrawer-paperAnchorLeft {
    // height: 87% !important;
    height: fit-content !important;
    width: 100%;
    border-radius: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    bottom: 0;
    top: unset !important;
    .swipe-container {
        display: flex;
        flex-direction: column;
        padding: 2%;
        padding-top: 2rem;
        height: fit-content;
        align-items: center;
        padding-bottom: 2rem;
        max-width: 700px;
    align-self: center;
        h3 {
            font-family: "Poppins";
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            text-align: left;
            color: #6a01c6;
        }
        li {
            font-family: "Poppins";
            font-size: 1.35rem;
            padding: 5px;
            font-weight: normal;
            font-style: normal;
            color: #000000;
            // min-height: 10rem;
            max-height: 10rem;
        }
        ul {
            li::marker {
                content: "●";
                color: #CB91FE;
                font-size: 26px;
            }
        }
    }
    button{
        color: white; 
        font-family: 'poppins',sans-serif ;
        font-weight: 2rem; 
        background-color: #6a01c6;
        height: 5rem ;
        width: 90%;
        border-radius: 15px; 
        margin-top: 20px;
    }
}
@media only screen and (min-width:700px) {
    .swipe-container { 
        justify-content: center; 
        padding: 1% !important;
        padding-top: 2rem !important;
        height: fit-content !important;
        h3{ 
            font-size: 2rem !important;
        }
        li{ 
            font-size: 1.55rem !important;
        }
        >button{ 
            width: 50% ;
            align-self: center;
        }
    }
}