.welcome-container
{
    background-color: #fff;
    // height: 100vh;
    width: 100%;
 .middle-div { 
    display: flex;
    width: 85%;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0rem;
    margin-top: 18vh;
    font-family: "Poppins", sans-serif;
    span.title-kleev {
        font-size: 2.25rem;
    color: black;
    font-weight: 300;
    font-family: inherit;
    width: 85%;
    margin-bottom: 12px;
            span { 
                color: white;
                font-weight:500;
                span {
                    color: #6807ae;
                }
            }
    } 
    div.logo-title{
        display: flex;
        align-content: center;
        align-items: center;
        gap: 5px;
        width: 85%;
        img{
            width: 60px;
            height: 70px;
        }
        span{
            font-size: 5rem;
            letter-spacing: -4px;
            font-weight: 600;
            color: #7F11E0;
        }
    }
    button {
        background-color: #7F11E0;
        border: none;
        width: 85%;
        margin-top: 24%;
        border-radius: 50px;
        max-width: 370px;
        font-weight: 500;
        position: absolute;
        bottom: 4rem;
        font-family: inherit;
        font-size: 1.8rem;
    }
    p {
        color: white ;
        font-family: 'inter',sans-serif ;
        font-weight: 200;
    }
 }
} 

@media screen and (min-width: 700px) {
    .welcome-container .middle-div span.title-kleev, 
    .welcome-container .middle-div {
        width: fit-content;
    }
    .welcome-container .middle-div div.logo-title {
        width: fit-content;
    }
}
