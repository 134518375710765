.mobile-header{ 
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    position: fixed;
    z-index: 5;
    span {
        font-size: 1.5rem;
    }
}
.pub-header{
    top: 0px;
    .numbers-div{
        width: 30% ;
        display: flex ;
        // justify-content: space-around;
        justify-content: center;
        align-items:center;
        gap: 5%;
        span{
            width: 31%;
            padding: 6% ;
            text-align: center;
            font-size: 1.3rem;
            color: #f1f1f1;

        }
        #selected{
            background-color: #6A01C6;
            color: white !important;
            border-radius: 50%;
        }
      
    }
    .retr-btn{
        color: #6A01C6 !important; 
        font-family: 'Poppins';
        font-size: 1.5rem !important;
    }
}

@media screen and (min-width: 700px) {
    #selected {
        width: 44px;
        height: 40px;
        display: flex;
        align-items: center;
    }
}