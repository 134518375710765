.dots-menu{
    z-index: 3 ;
    background-color: #CB91FE ;
    position: fixed;
    right: 20px;
    top: 30%;
    // align-items: center;
    display: flex ;
    justify-content: center;
    flex-direction: column;
    width: 10rem ;
    border-radius: 12px;
    span{
        font-family: 'Poppins', sans-serif;
        text-align: center;
        font-size: 1.5rem ;
        color: white;
        align-items: center;
        padding: 6px 10px;
    }
    span:first-of-type{
        border-bottom: solid 1px white ;
    }
}