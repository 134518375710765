@import 'fonts';
@import 'colors';
@import "~bootstrap/scss/bootstrap";
@import 'forms';
@import 'buttons';
@import 'spaces';
@import 'nav';


body{
    font-family: 'Montserrat-Medium',sans-serif;
    font-size: 14px;
    color: $text-color;
    background-color: $background;
}

h1,h2,h3{
    font-family: 'Montserrat-Bold',sans-serif;
}
h1{
    font-size: 3em !important;
    margin-bottom: 15px !important;
}
h4{
    font-size: 1.7em;
    color: $grey2;
    font-family: 'Montserrat-Medium',sans-serif;
}
.flexspace{ 
    justify-content: space-between;
    align-items: center;
}
.mb-13{
    margin-bottom:50px;
}
.main {
    min-height: 100vh;
    display: flex;
    .right_bloc{
      width: 66%;
       margin-top:3em;

     h1{
       margin-top:0px
      }
      h4{
       margin-bottom:90px
      }
      .form-check-label,a.terms{
        color:$grey5
      }
      a.terms{
        margin-left: 6px;
        border-bottom: 1px dashed $grey5;
      }
      .form-label span{
       color:$grey7;

      }
    }

}
.icoinput{
    position: relative;
    background-position: 98% center !important;
    background-repeat: no-repeat !important;
    background-size: 20px !important;
}
.icomail{
    background-image: url(../../assets/images/icomail.jpg);
}
.icopassword{
   background-image: url(../../assets/images/icopassword.jpg);
}
.avatar{
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: $grey5;
    background-image: url(../../assets/images/default.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &.lg{
        width: 107px;
        height: 107px;
        cursor: pointer;
    }
}


a{
    text-decoration: none;
}

a{
    position: relative;
    .basket{
        position: absolute;
        top: -10px;
        right: 0;
        background-color: $primary;
        min-width: 20px;
        min-height: 20px;
        border-radius:50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

progress {
    vertical-align: baseline;
}

template,
[hidden] {
    display: none;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
    outline-width: 0;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}

b,
strong {
    font-weight: inherit;
}

b,
strong {
    font-weight: bolder;
}

dfn {
    font-style: italic;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

mark {
    background-color: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

figure {
    margin: 1em 40px;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

button,
input,
select,
textarea {
    font: inherit;
    margin: 0;
}

optgroup {
    font-weight: bold;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0; /* 3 */
    white-space: normal;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}


 
 

@media only screen and (max-width: 760px) {
    
   .right_bloc{
     width:100% !important;
     padding-left:10%;
     padding-right:10%;
   }
   .left_bloc{
     display:none;
   }

}
@media only screen and (max-width: 560px) {
    
   .right_bloc{
     padding-left:5%;
     padding-right:5%;
   }
   .left_bloc{
     display:none;
   }


}

 
