.profile-depp {
    min-height: 100vh;
    background-color: #6A01C6;
    display: flex;
    flex-direction: column;
    .profile-depp-head {
        width: 100%;
        display: flex;
        height: 10.5rem;
        padding: 15px 10px 10px;
        .kleever-log {
            background-image: url('../../assets/images/kleevermainlogo1.png');
            width: 23%;
            background-repeat: no-repeat;
            background-size: contain;
            min-height: 100%;
        }
    }
    .setting-part {
        background-color: #cb91fe;
        min-height: 90vh;
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .setting-part-head {
            height: 10%;
            width: 100%;
            min-height: 12rem;
            gap: 1rem;
            display: flex;
            align-items: center;
            justify-content: normal;
            border-bottom: solid 3px #fef7f742;
            .check-div {
                min-height: 9rem;
                width: 19%;
                display: flex;
                gap: 4rem;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                span {
                    font-family: 'Poppins';
                    font-size: 1.2rem;
                    font-weight: 300;
                    width: 42%;
                }
                .form-switch {
                    width: 5rem !important;
                    max-width: 5rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .form-check-input {
                        width: 15%;
                        max-width: 5rem;
                        height: 2.5rem;
                        background-image: url('../../assets/images/green-circle-icon.svg');

                    }
                    .form-check-input:checked {
                        background-image: url('../../assets/images/red-circle-icon.svg');
                        background-color: white;
                    }
                }
            }
            span {
                font-family: 'Poppins';
                font-size: 1.3rem;
                color: black;
                width: 55%;
                // margin-left: 1rem;
                font-weight: 500;

            }
            .profile-pic {
                width: 65px;
                max-width: 10rem;
                background-repeat: no-repeat;
                background-size: cover;
                min-height: 65px;
                background-position: center;
                border-radius: 50%;
                margin-left: 5px;
            }
        }
        .setting-nav-part {
             min-height: 28rem;
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 4rem;
            border-bottom: solid 3px #fef7f742;
            .nav-item {
                display: flex;
                width: 90%;
                justify-content: flex-start;
                gap: 1rem;
                align-items: center;
                svg:first-of-type{
                    background-color: white ;
                    border-radius: 50%;
                    padding: 11px;
                }
                span{
                    font-family: 'Poppins' ;
                    font-size: 1.7rem ;
                    color: white ;
                    font-weight: 400 ;
                    width: 73%;
                    
                }
            }
            .refresh-pos{
                width: 90% ;
                max-width: 60rem;
                max-height: 5rem;
                color: black ;
                display: flex ;
                align-items: center ;
                justify-content: space-around;
                background-color: white ;
                font-family: 'Poppins';
                font-size: 1.3rem ;
                color: #6A01C6;
                font-weight: 500;
                border-radius: 13px;
                font-weight: bold;
                filter: drop-shadow(0px 10px 3px rgba(0,0,0,0.16 ));
                span{
                    width: 80%;
                }
                svg{ 
                    max-height: 4rem;
                    height: 100% ;
                    width: 10%; 
                    color: #fa1717;
                }
            }
        }
        .bottom-setting-part{
            min-height: 20rem;
            width: 85%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 2rem;
            // align-items: center ;
            padding-top: 4rem;
            span{
                text-align: center;
                color: black ;
                font-family: 'Poppins' ;
                font-size: 1.3rem;
                font-weight: 200 ;
            }
            .contact-div{
                margin: 0 auto;
                height: 5rem; 
                max-width: 50rem;
                width: 100%;
                display: flex;
                justify-content: space-between;
                img{
                    height: 100%;
                    min-width: 15%;
                    background-color: #dcdcdc80; 
                    border-radius: 25%; 
                    padding: 10px;
                    object-fit: contain;
                }
            }
           span.logout { 
            text-decoration: none ;
            align-self: center;
            font-family: 'Poppins' ;
            color: black ;
            font-weight: 200;
            margin-top: 3rem;
           }
        }
        .demands-container {
            width: 100%;
            margin-top: 1rem;
            
            .demands-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                .demands-title {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    h2 {
                        color: white;
                        font-size: 1.4rem;
                        font-weight: 500;
                        font-family: 'Poppins';
                    }
                }

                .refresh-button {
                    background: transparent;
                    border: none;
                    padding: 0.5rem;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .spinning {
                        animation: spin 1s linear infinite;
                    }
                }
            }

            .demands-list {
                padding: 1rem;

                .no-demands {
                    text-align: center;
                    padding: 2rem;
                    color: rgba(255, 255, 255, 0.7);
                    font-family: 'Poppins';
                    font-size: 1.2rem;
                }

                .demand-card {
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 12px;
                    padding: 1.2rem;
                    margin-bottom: 1rem;

                    .demand-info {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-bottom: 1.2rem;

                        .client-details {
                            .client-name {
                                color: white;
                                font-size: 1.3rem;
                                font-weight: 500;
                                margin-bottom: 0.5rem;
                            }

                            .client-address {
                                color: rgba(255, 255, 255, 0.7);
                                font-size: 1.1rem;
                            }
                        }

                        .distance {
                            color: #6a02c6;
                            font-weight: 500;
                            font-size: 1.1rem;
                            background: rgba(106, 2, 198, 0.1);
                            padding: 0.4rem 1rem;
                            border-radius: 20px;
                        }
                    }

                    .demand-actions {
                        display: flex;
                        gap: 1rem;

                        button {
                            flex: 1;
                            padding: 1rem 1.2rem;
                            border: none;
                            border-radius: 8px;
                            font-family: 'Poppins';
                            font-size: 1.1rem;
                            font-weight: 500;
                            cursor: pointer;
                            transition: all 0.2s ease;

                            &.accept-btn {
                                background: #6a02c6;
                                color: white;

                                &:hover {
                                    background: #5b01ab;
                                }
                            }

                            &.reject-btn {
                                background: rgba(255, 255, 255, 0.1);
                                color: white;

                                &:hover {
                                    background: rgba(255, 255, 255, 0.2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media  only screen and (min-width:900px){
    .refresh-pos{
        justify-content: center !important;
    }
    .setting-part{
        width: 78%;
        align-self: center;
        .setting-part-head{ 
            justify-content: space-around !important;
        }
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}