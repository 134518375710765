.vhei-info{ 
    display: flex ;
    flex-direction: column ;
    width: 100%;
    min-height: 48rem;
    gap: 20px;
    >p{
        display: flex; 
        gap: 2rem;
        span{
            font-family: 'Poppins' ;
            font-size: 1.1rem ;
            font-weight: 300;
            opacity: 0.6;
        }
    }
  
    input#react-select-7-input::placeholder{
      text-align: center;
    }
    .fake-select{
         min-height: 5rem ;
         background-color: #f1f1f1;
         display: flex ;
         justify-content: center ;
         border: none; 
         align-items: center;
         span{ 
            font-family: 'Poppins';
            color: gray;
            font-size: 1.5rem;
            width: 19%;
            text-align: center;
         }
        }
}
#rev{
    transform: scaleX(-1);
}
