.accept-drawer {
    .MuiDrawer-paperAnchorLeft {
        height: 23% !important;
        position:relative !important ;
        top: 50% !important;
        width: 100%;
        border-radius: 15px; 
        .accept-drawer-container{
            height: 100% ;
            display: flex ;
            flex-direction: column ;
            justify-content: space-around;
            padding: 5%;
            p{
                font-family: 'Poppins';
                font-size: 1.5rem;
                font-family: 300 ;
                color: black;
                span{
                    color: green; 
                }
                #danger { 
                    color: red ;
                }
            }
        }
        .buttons-div{
            display: flex ;
            width: 100% ;
            height: 10% ;
            min-height: 5rem;
            justify-content: space-between;
            align-items: center;
            button{
                width: 40% ;
                height: 100%;
                margin: 0 !important;
            }
            button:nth-of-type(2)
            {
                background-color: white ;
                color:#ab3cd3 ;
                border: solid 0.5px #ab3cd3;

            }
        }
    }
}
@media only screen and (min-width:700px){
    .accept-drawer .MuiDrawer-paperAnchorLeft
    { 
        min-height: 23% !important;
    max-height: 28%;
    position: relative !important;
    top: 50% !important;
    left: 13% !important;
    width: 74%;
    border-radius: 15px;
    }
    .accept-drawer-container{
        gap: 15px ;
    } 
}