.frag-container {
    display: flex;
    justify-content: flex-end;
}
button.btn.btn-side {
    background-color: #7F25CE;
    border-radius: 50%;
    padding: 8px;
    position: absolute;
    right: 8px;
    top: 10px;
    z-index: 100;
    display: block;
}
.MuiDrawer-paperAnchorRight {
    width: 78% !important;
    background-color: #BA6DFE !important;
    max-width: 600px;
    button {
        font-family: 'inter',sans-serif;
        font-size: 1.2rem;
    }
    .side-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 13px;
        gap: 7px;
        >span { 
            text-align: center; 
            font-size: 1.3rem ;
            text-decoration: underline ;
            color: #ECEBEB ;
            opacity: 0.69;
            margin-top: 2rem;
    
        }
    }
    .side-item {
        display: flex;
        // padding: 4%;
        justify-content: space-around;
        min-height: 47px;
        img {
            height: 37px;
            width: 40px;
        }
        span {
            color: rgb(51, 51, 51);
            font-family: 'inter',sans-serif;
            font-weight: 500;
            font-size: 1.65rem;
        }
    }
    .side-head {
        padding: 3%;
        width: 100% !important ; 
        height: 4rem ;
        align-items: center;
        gap: 18rem;
        span{
            font-family: 'inter';
            font-size: 1.5rem;
        }
        .sidebar-header-mobile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-right: 15px;

            .notification-container {
                .notification-icon {
                    color: white;
                }

                .notification-dropdown {
                    right: 0;
                    top: 45px;
                }
            }
        }
    }
    .side-contact {
        justify-content: flex-end;
        gap: 10px;
        min-height: 5rem !important;
        padding: 8px;
        width: 100% !important;
        img,
        svg {
            background-color: white;
            filter: drop-shadow(2px 4px 8px black);
            border-radius: 50%;
            width: 26px;
            height: 26px;
            padding: 5px;
            object-fit: contain;
            object-position: center;
        }
    }
    .side-profile {
        justify-content: space-between;
        min-height: 14rem;
        border-bottom: solid 3px #E7CCFF;
        align-items: center;
        padding-left: 6px;
        padding-right: 9px;
        .avatar-sec {
            width: 25%;
            height: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                height: 100%;
                max-width: 85px;
                min-width: 70px;
                min-height: 70px;
                max-height: 85px;
                border-radius: 50%;
                object-fit: cover;
                object-position: center;
            }
            span {
                font-family: 'poppins',sans-serif;
                font-weight: 600;
                font-size: 1.35rem;
            }

        }

        .new-pub-btn {
            background-color: #6A01C6;
            border: solid 1px white;
            display: flex;
            font-size: 10px;
            padding: 12px 14px;
            width: max-content;
            justify-content: center;
            height: auto;
            align-items: center;
            border-radius: 8px;
            font-family: 'poppins', sans-serif;
            letter-spacing: 0.2px;
            #plus {
                border: solid 1px white;
                border-radius: 50%;
                width: 16px;
                height: 16px;
            }
        }
    }
    .nav-item {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        justify-content: flex-start;
        padding: 1%;
        min-height: 6rem;
        padding-left: 4rem;
        svg:first-of-type {
            background-color: white;
            padding: 7px;
            border-radius: 40px;
        }
        span {
            font-family: 'Poppins',sans-serif;
            color: white;
            font-size: 1.25rem;
            width: 65%;
            font-weight: 300;
        }
    }
    .depanneur-case {
        height: 15%;
        height: 6.4em;
        padding: 3%;
        display: flex;
        justify-content: center;
        background-color: #CB91FE;
        button {
            background-color: white;
            color: #7F25CE;
            width: 73%;
            font-family: 'Poppins';
            font-weight: bold;
            border-radius: 20px;
            font-size: 1.35rem;
            padding: 3px;
            min-height: 4rem;
            height: 40%;
            align-self: center;
        }
    }
  
}
@media  only screen and (min-width:700px){
    button.btn.btn-side{
        display: none;
    }
    .MuiDrawer-paperAnchorRight {
        width: 60% !important;
        background-color: #BA6DFE !important;
        max-width: 500px;
    }
    .MuiDrawer-paperAnchorRight .side-profile .avatar-sec img { 
        max-height: 85px;
        max-width: 85px;
        min-width: 80px;
        min-height: 80px;
        object-fit: contain;
    }
    .side-contact {
    height: 2rem ;
        >img{ 
            max-width: 4rem;
        }
    } 
    .side-profile{ 
        min-height: 13rem !important;
        max-height: 13rem !important;
    }
    .side-container {
        gap: 2rem;
    } 
    .side-item{
        svg{
            font-size: 30px !important;
        }
    } 
    .side-head { 
        justify-content: space-between !important ;
    } 
    .nav-item { 
        svg:first-of-type { 
        padding: 7px !important;
        } 
    }
}
