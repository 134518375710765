.css-13cymwt-control
{
    background-color: #f1f1f1 !important;
    border: none;
    min-height: 50px;
    // min-width: 37rem !important;
    max-width: 33rem!important;
    .css-qbdosj-Input
    {
        min-height: 50px;
    }
    .filter-select  {
        pointer-events: none;
    }
}

