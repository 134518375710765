$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;
.new-pub-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: fit-content;
    align-items: center;
    padding: 22px;
    max-width: 700px;
    gap: 1.5rem;
    .css-1u9des2-indicatorSeparator {
        display: none;
    }
        .select-option {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 6rem;
            background-color: #f1f1f1;
            img {
                min-height: 6px !important;
                max-height: 84% !important;
                padding: 5px;
                background-color: transparent !important;
                border-radius: 50% !important;
                width: 17% !important;
            }
            span {
                font-family: 'Poppins', sans-serif;
                color: gray;
                text-align: center;
                font-weight: 500;
                font-size: 1.6rem;
            }
            button {
                height: 35px;
                padding: 6px;
                max-width: 100px;
                background-color: #7f11e0;
            }
        }
    .css-b62m3t-container
    {
        margin-bottom: 2rem;
    }
    .css-1u9des2-indicatorSeparator {
        display: none;
    }
    .css-13cymwt-control {
        border: none;
            min-height: 6rem;
    }
    .css-1hb7zxy-IndicatorsContainer {
        background: #f1f1f1 !important;

    }
    .css-1fdsijx-ValueContainer,.css-hlgwow {
        background: #f1f1f1;
        .css-1jqq78o-placeholder{
            font-family: 'Poppins' !important;
            font-size: 2rem !important;
            width:100% !important; 
            display: flex !important;
            justify-content: center !important;
        }
        .select-option {
            justify-content: flex-start;
            span {
                color: #7f11e0;
                width: 82%;
                font-size: 1.4rem;
                font-family: 'Poppins';
                font-weight: 400;
                text-align: center;
            }
            button {
                display: none;
            }
            img {
                height: 70% !important;
                padding: 5px;
                border-radius: 50% !important;
                background-color: white !important;
                border: 1px solid #7f11e0;
                width: 13% !important;
                object-fit: contain;
            }
        }
    }
    .css-1fdsijx-ValueContainer:active,.css-hlgwow:active { 
        border: unset ;
    }
    .css-1nmdiq5-menu {
        background-color: white;
        *:focus , *:active , *:hover , *:visited{
            background-color: unset ;
            
            border: unset ;
        }
        .disabled{
            display: none;
        }
        .css-tr4s17-option,.css-d7l1ni-option
        {
            background-color: white;
        }
        .select-option {
            background-color: white;
            width: 100% ;
            justify-content: space-between;
            // gap: 10%;
        }
        button {
            height: 35%;
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Poppins';
            font-size: 0.8rem;
            font-family: 200;
            font-weight: 200 ;
            padding: 1px;
            border-radius: 25px;
        }
        img {
            min-height: 80% !important;
            max-height: 90%;
            min-width: 78px ;
            object-fit: cover;
        }
        span {
            font-family: 'Poppins';
            font-size: 1.45rem;
            width: 60% !important;
            font-weight: 300;
        }
    }
    .infos-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        span {
            font-family: 'Poppins';
            font-size: 1.6rem;
            font-weight: 500;
            color: black;
        }
        input[type='text'] {
            height: 5rem;
            width: 100%;
            background-color: #f1f1f1;
            border: solid 1px hsl(0, 0%, 80%);
            border-radius: 7px;
            border: none;
        }
        .fake-select{
            min-height: 5rem ;
            background-color: #f1f1f1;
            display: flex ;
            justify-content: center ;
            border: none; 
            align-items: center;
            span{ 
               font-family: 'Poppins';
               color: gray;
               font-size: 1.5rem;
               width: 19%;
               text-align: center;
            }
           }
    }
    .description-input {
        height: 22rem;
        width: 100%;
        border-radius: 9px;
        border: solid 1px hsl(0, 0%, 80%);
        background: #E6E5E5;
        padding: 10px;
    }
    ::placeholder {
        color: black;
        text-align: center;
    }
    .check-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 19rem;
        gap: 2rem;
        padding-top: 3rem;
        p {
            font-family: 'Poppins';
            font-size: 1.8rem;
            color: black;
        }
        .choice {
            width: 100%;
            display: flex;
            justify-content: space-between;
            span {
                color: black;
                opacity: 0.76;
                font-size: 1.5rem;
                font-family: 400;
            }
        }
    }
    .first-img-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        p {
            font-family: 'Poppins';
            font-size: 1.5rem;
            font-weight: 500;
            color: black;
        }
        span {
            color: #000000;
            opacity: 0.69;
        }
        .photos-grid {
            display: grid;
            grid-template-columns: repeat(2, 39%);
            height: 40rem;
            width: 100%;
            gap: 7rem;
            row-gap: initial;
            justify-content: center;
            align-items: center;
            .add-photo-case {
                height: 70%;
                max-width: 20rem;
                min-height: 145px;
                min-width: 140px;
                background-color: transparent;
                border: solid 1px #7f11e0;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                input {
                    opacity: 0;
                }
                img {
                    height: 15%;
                    align-self: center;
                }
                .deleteIc{
                    position: absolute;
                    top: -13px;
                    left: -9px;
                    font-size: 28px;
                    color: #673AB7;
                }
            }
            .case-back{
                background-size: cover !important;
                background-position: center !important;
            }
        }
    }
}
@media only screen and (min-width:700px){
    .new-pub-container {
        width: 70%;
        align-self: center;
        padding-top: 1rem;
    } 
}
