.otp-input{
    height: 70px ;
    border-radius: 8px;
    border: none;

} 
.input-groupo{
    display: flex;
    align-items: center;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    height: 55px;
    padding-left: 8px;
    label {
        width: 35px;
        height: 30px;
    position: relative;
    div{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 50px;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 3px;
        background-color: #fff;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: white;
    }
    hr{
        border: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: 10px;
    width: 22px;
    height: 1.75px;
    opacity: 0.5;
    background-color: #000;
    margin: 0;
    padding: 0;
    }
    span{
        border: none;
    position: absolute;
    bottom: 20px;
    right: 10px;
    left: 10px;
    width: 22px;
    height: 1.75px;
    margin: 0;
    padding: 0;
    }
    input{
        position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 60px;
    opacity: 0;
    }
  }

}

  