$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;
.show-all-container{
    background-color: #f3f3f3;
    // min-height: 100vh;
    // max-height: fit-content;
    // height: 100vh;
    display: flex ;
    flex-direction: column ;
    padding: 15px;
    gap: 4rem;
    padding-top: 12rem; //4rem
    .filter-buttons {
        position: fixed;
        z-index: 4;
        display: flex;
        gap: 1rem;
        right: 15px;
        top: 92%;
        
        .filter-btn, .reset-btn {
            background-color: #CB91FE;
            display: flex;
            align-items: center;
            font-family: 'Poppins';
            justify-content: center;
            gap: 1.2rem;
            height: 4rem;
            padding: 1px 2rem;
            font-size: 1.5rem;
            border-radius: 10px;
            transition: all 0.3s ease;
        }
        
        .reset-btn {
            background-color: #6c757d;
            animation: slideIn 0.3s ease;
        }
    }
    .input-group { 
        border-radius: 55px;
        flex-wrap: nowrap;
        .input-group-prepend
        {
            .search-icon { 
                padding: 1rem;
                display: flex;
                align-items: center;
                width: 4rem;
                height: 3.5rem;
                background-color: white;
                border-radius: 10px 4px 0px 10px;
                svg{
                    font-size: 1rem;
                    width: 18px;
                    opacity: 0.6;
                }
            }
        }
        input {
            border: none !important;
            border-radius: 0px 7px 7px 0px  !important;
            height: 3.5rem;
            max-width: 93% !important;

        }
        input::placeholder {
        opacity: 0.6 !important;
        padding-left: 4rem;
        }

    }
    .filter-item {
        display: flex ;
        justify-content: flex-end ;
        height: 3rem !important;
        button {
            background-color: white;
            color: grey ;
            border-color: rgba(195, 195, 195, 0.518) !important;
            // max-width: 147px;
            font-family: 'Poppins',sans-serif ;
            font-weight: 300;
            // width: 29%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 20px; 
            font-size: 1rem ;
            // max-width: 150px;
            // min-width: 150px;
        }
    }
    .show-items-grid{ 
        display: grid; 
        grid-template-columns: repeat(3,30%);
        gap: 54px;
    }
}
@media only screen and (max-width:700px) {
   
    .show-all-container{
        padding-top: 10rem;
        .show-items-grid{
            grid-template-columns: repeat(1,100%) !important;
        }
    }
    
}

@media only screen and (max-width:1000px) {
   
    .show-all-container{
        .show-items-grid{
            grid-template-columns: repeat(2,48%);
        }
    }
    
}

.sorry-div{
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg{
        font-size: 130px;
        background: #cb91fe;
        border-radius: 50%;
        padding: 20px;
    }
    span{
        text-align: center;
    }
    span:first-of-type{
        font-size: 1.75rem;
        font-weight: 600;
    }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}