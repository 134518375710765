.pagi-cont {
    width: 58% !important;
    max-width: 25rem;
    align-self: end;
    ul {
        min-width: 15rem;
        padding: 1rem;
        background-color: white;
        border-radius: 20px;
        justify-content: space-between;
        li {
            a {
                color: black;
                border: 0;
                border-radius: 20px;
                // background-color: red;
            }
          
        }
    li:nth-of-type(2){
        a:first-of-type{
            background-color: blueviolet;
            color: white;
        }
    }
    }
}
