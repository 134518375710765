#toast-container {
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.toast {
  display: flex !important;
  opacity: 1 !important;
  background: white;
  border-radius: 8px;
  padding: 16px;
  min-width: 300px;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  animation: slideInFromRight 0.3s ease-out;

  &:not(.show) {
    display: flex !important;
  }

  .toast-content {
    position: relative;
    padding-right: 20px;

    h4 {
      margin: 0 0 8px 0;
      font-size: 16px;
      font-weight: 500;
      color: #333;
    }

    p {
      margin: 0;
      font-size: 14px;
      color: #666;
    }
  }

  button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    font-size: 20px;
    color: #999;
    cursor: pointer;
    padding: 4px;

    &:hover {
      color: #333;
    }
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
} 