$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;
.choose-container {
    height: 100vh;
    padding: 0 !important;
    position: relative;
    .choose-banner {
        background-image: url('../../assets/images/banner1.jpeg');
        height: 55%;
        width: 100%;
        position: absolute;
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;
        filter: brightness(0.5);
    }
    .choose-control {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        height: 50%;
        width: 100%;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 25px;
        bottom: 0;
        .ok-btn {
            background-color: #7F11E0;
            color: white;
            width: 90%;
            border-radius: 15px;
            font-family: 'inter',sans-serif;
            font-size: 1.9rem;
            font-weight: 300;
            padding: 14px 16px !important;
        }
        
        p {
            font-size: 1.9rem;
            font-family: 'inter',sans-serif;
            font-weight: 300;
            color: black;
            #type-span {
                font-weight: bold;
                font-size: 2rem;
            }
        }

        .swipe-case {
            justify-content: space-between;
            display: flex;
            align-items: center;
            height: 180px;
            padding: 3%;

            button {
                padding: 0;
                border-radius: 50%;
                background-color: #7F11E0;
                color: white;
                min-height: 25px;
                max-height: 35px;
                min-width: 25px;
                max-width: 35px;
                svg{
                    margin-bottom: 2px;
                }
            }
            img {
                width: 70% !important;
                max-width: 300px !important;
            }
            img.moto{
                min-width: 70% !important;
                max-width: 250px !important;
            }
            img.depanneur {
                min-width: 70% !important;
                max-width: 250px !important;
            }
        }
    }
}
@media screen and (min-width: 700px) {
    .choose-container +.navbar-app{ 
        display: none !important;
    }
    .input-group{
       input{  max-width: 97%;}
    }
    .choose-container{ 
        justify-content: center;
        position: relative;
        // min-height: 100vh;
        background-color: #7F11E0;
        align-items: center !important;
        .choose-banner { 
            height: 60%;
            background-image: none !important ;
            display: none;
        }
        .choose-control{
            // gap: 55px ;
            height: fit-content;
            // max-height: 50rem;
            padding: 3%;
            top: 20%;
            width: 80%;
            left: 10%;
            align-self: center;
            .swipe-case { 
            }
            .ok-btn { 
                width: 50% ;
            }
        }
    }
}

.sl-spinner2 {
    position: fixed;
    top: 50%;
    z-index: 100;
    left: 50%;
    width: 100%;
    background: #673ab766;
    height: 100vh;
    transform: translate(-50%, -50%);
  }

  