.mb-40{
    margin-bottom: 40px;
}
.mb-32{
    margin-bottom: 32px;
}
.mb-24{
    margin-bottom: 24px;
}
.mb-45{
    margin-bottom: 45px;
}
.mb-60{
    margin-bottom: 60px;
}
.mt-45{
    margin-top: 45px;
}
