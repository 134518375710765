.change-pos-drawer{
    align-items: center;
    .MuiDrawer-paperAnchorLeft {
        height: 38rem !important;
        bottom: 18% !important;
        border-radius: 25px !important;
        width: 90% !important;
        left: 5%;
        .change-pos-comp{
        display: flex;
        flex-direction: column;
        max-height: 100%;
        align-items: center;
        padding-top: 2rem;
        padding-bottom: 2rem;
        >span:first-of-type{
            font-family: 'Poppins';
            font-size: 1.5rem;
            font-weight: 500;
        }
        .done-div{
            display: flex ; 
            width: 100% ;
            align-items: center ;
            min-height: 17rem;
            justify-content: center;
            img{
                width: 40%; 
                padding: 20px;
                height: 85%;
                background-color: #00B023; 
                border-radius: 50% ;
            }
        }
        .close-btn{
            font-family: 'Poppins' ;
            font-size: 1.5rem ;
            display: flex; 
            margin: 56px;
            align-items: center ;
            justify-content: center;
            border-radius: 15px !important;
        }
        .marker-div{
            width: 100% ;
            display: flex ;
            justify-content: center;
            align-items: center;
            gap:1rem;
            span{
                font-family: 'Poppins';
                font-size: 16px;
                opacity: 0.7;
            }
        }
    }
   
    }
}
@media only screen and (min-width:700px){
    .change-pos-drawer{
        align-items: center;
        .MuiDrawer-paperAnchorLeft {
            width: 20% !important;
            left: 40%;
        }
    }
    .change-pos-comp{ 
        padding-top: 3rem ;
        padding-bottom: 3rem;
    } 
}