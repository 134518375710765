.my-pubs-comp{
    width: 100% ;
    background-color: white ;
    display: flex ;
    height: 16rem;
    padding: 2%;
    border-radius: 13px;
    filter: drop-shadow(0px 10px 3px rgba(0,0,0,0.16 ));
    .img-div{
        width: 50%;
        // background-image: url('../../assets/images/nissan.png');
        height: 94%;
        min-width: 17rem;
        background-repeat: no-repeat ;
        background-size: cover;
        align-self: center;
        border-radius: 20px ;
        min-width: 15rem;
        background-position: center;
    }

    .text-div{
        width: 70%;
        padding: 3%;
        >span{
            font-family: 'Poppins';
            font-size: 1.3rem;
            font-weight: 400;
        }
        .mini-head{
            width: 100% ;
            display: flex ;
            justify-content: space-between;
            align-items: center;
            span{
                font-family: 'Poppins';
                font-size: 1.3rem;
                font-weight: 500;
            }
            button{
                background-color: transparent;
                border: none;
            }
        }
        .price_statut_annonce{
            display: flex;
            flex-direction: column;
            gap: 8px;
            span:last-child.en_cours{
                color: gray;
                font-weight: 400;
            }
            span:last-child.accepted{
                color: green;
                font-weight: 400;
            }
            span:last-child.refused{
                color: red;
                font-weight: 400;
            }
        }
    }

}