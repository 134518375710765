$breakpoint-xs: 450px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;
.reserve-info-page {
    width: 100%;
    padding: 1rem 5% 5%;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    max-width: 700px !important;
    h3 {
        font-family: 'Poppins';
        font-size: 1.7rem;
        font-weight: 600;
        margin-bottom: 2rem; 
    }
    .accept-div {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 11px;
        // margin-bottom: 13rem;
        margin-bottom: 9rem;
        p {
            font-family: 'Poppins';
            font-size: 1.1rem;
            margin-top: 7px;
        }
    }
    .phone-picker{
        width: 100%;
        input {
        height: 55px;
        width: -webkit-fill-available;
        border-right: unset;
        border: 0.5px solid #000000a3;
    }
    .react-international-phone-country-selector-button {
        height: 100%;
    }
    div.flag-dropdown{
        border: 0.5px solid #000000a3;
        border-right: unset;
    }
    } 
    .top-div {
        height: 16%;
        display: flex;
        flex-direction: column;
        gap: 23px;
        width: 100%;
        span {
            font-size: 1.4rem;
            font-family: 'Poppins';
            margin-top: 1rem;
        }
        .country-pick {
            display: flex;
            height: 100%;
            gap: 3rem;
            width: 100%;

            .css-b62m3t-container {
            width: 100%;
            }
            .css-1n6sfyn-MenuList{
                .select-option{
                    display: flex;
                    justify-content: space-between;
                    button{ 
                        color: #6a02c6 !important;
                        font-size: 1rem;
                    }
                }
            }
            .css-13cymwt-control {
                background-color: #ffff !important;
                min-height: 50px;
                border:solid 0.5px black ; 
                border-radius: 7px; 
                width: 100%;
                .css-1hb7zxy-IndicatorsContainer {
                    display: none;
                }
                .css-qbdosj-Input {
                    min-height: 50px;

                }
                .select-option {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    *{
                        margin: 0 !important;
                    }
                    button {
                        color: #6a02c6;
                        font-size: 1rem;
                    }
                }
            }
            .css-tr4s17-option 
            {
                .select-option{
                    button{
                        color: #6a02c6;
                    }
                }
            }

        }
    }
    .confirme-btn {
        background-color: gray;
        border-radius: 30px;
        font-family: 'Poppins';
        font-size: 1.6rem;
    }
}
@media only screen and (min-width: $breakpoint-md) {
    .reserve-info-page{ 
        width: 80%; 
        align-self: center;
        align-items: center;
        padding-top: 8rem;
        >button{
            width: 60%;
        }
    }
    .top-div {
        min-height: 11rem;
        .country-pick {
        margin-bottom: 7px !important;
            .change-btn {
                right: 10rem !important;
            }
        }
    }
}

.checkVal-conf{
    background-color: #6a02c6 !important;
}