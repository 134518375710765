$primary:#abc3e9;
$primary-transparent:rgba(205, 52, 39, .2);
$dark: #1e1f28;
$text-color: #1e1f28;
$background: #FFF;
$grey: rgba(244, 246, 251, .3);
$grey2:#677788;
$grey3:#a9b6c4;
$grey4:#eeeeff;
$grey5:#97a4af;
$grey6:#d7d5dd;
$grey7:#8c98a4;
$green: #80adb2;
$blue:#0d6dfd;
$blue-transparent:rgba(30 ,31 ,40 , .3);
$secondary:white;



