.reservation-stripe{ 
    width: 100% ;
    z-index: 20 ;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #6A01C6;
    height: 8rem;
    display: flex;
    align-items: center ;
    padding: 8px;
    gap: 7rem;
    .tarif-div{ 
        width: 50% ;
        font-family: "Poppins";
        display: flex ;
        flex-direction: column ;
        span:first-of-type { 
            color: white ;
            font-weight: 500;
            font-size:1.8rem ;
        }
        span{ 
            font-size: 1rem ;
            font-weight: 300 ;
            color: white ;
        }
    }
    button  {
        background-color: white ;
        color: #6A01C6 ;
        padding: 1%;
        width: 30%;
        height: 4rem;
        max-width: 300px;
        font-family: "Poppins";
        font-size: 1.5rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    button:hover{
        color: white ;
        background-color: #902ae9 ;
        border: 1px solid #fff;
    }
}
@media only screen and (min-width:900px) {
    .reservation-stripe
    { 
    justify-content: center ;
    }
}