.active-request-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.map-container {
  width: 100%;
  height: 100%;
}

.info-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 1000;
}

.back-button {
  background: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  
  &:hover {
    background: #f8f8f8;
  }
}

.status-card {
  background: white;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  h2 {
    color: #6a02c6;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }

  .status-details {
    p {
      margin: 0.5rem 0;
      color: #666;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .action-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    button {
      flex: 1;
      padding: 0.8rem;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-weight: 500;
      transition: all 0.2s ease;

      &.call-button {
        background-color: #4CAF50;
        color: white;

        &:hover {
          background-color: #45a049;
        }
      }

      &.complete-button {
        background-color: #6a02c6;
        color: white;

        &:hover {
          background-color: #5b01ab;
        }
      }
    }
  }
}

.location-error-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ff4444;
  color: white;
  padding: 10px;
  text-align: center;
  z-index: 1000;
} 