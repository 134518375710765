.main-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-top: 7.5rem;
    // background: linear-gradient(181deg, #6A01C6 23%, #240043 58%, #8517E5 79%, #7e15d9 20%) ; 
    background: linear-gradient(181deg, #6A01C6 23%, #240043 60%, #8517E5 79%, #7e15d9 20%);
    .kleever-logo {
        height: 13rem;
        width: 12rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        background-color: white;
        filter: drop-shadow(0px 20px 3px rgba(0,0,0,0.34 ));
        border-radius: 25px;
        margin-bottom: 8px;
        img{
            min-height: 18rem;
            min-width: 17rem;
            object-fit: contain !important;
            width: 100%;
        }
    }
    .kleever-logo+span{
        font-family: 'Poppins';
        font-size: 5rem;
        font-weight: 500;
        color: white;
        margin-bottom: -12px;
        span{
            font-size: 5.3rem;
            color: #CAA4EB;
            font-weight: 500;
        }
    }
    > span:nth-of-type(2) {
        font-family: 'Poppins';
        font-size: 1.35rem;
        font-weight: 300;
        color: white;
        margin-bottom: 10%;
    }
    .cars-div {
        height: 33rem;
        width: 100%;
        flex-direction: column;
        min-width: 41rem;
        background-size: cover;
        display: flex;
        z-index: 1;
        align-items: center;
        .cars-element {
            min-height: 21rem;
            width: 100%;
            background-image: url('../../assets/images/carsbanner.png');
            background-size: cover;
            background-position: center;
        }
        .ways-div {
            display: flex;
            justify-content: flex-start;
            min-width: 9rem;
            height: 12%;
            width: 40rem;
            top: 50%;
            position: absolute;
            overflow: hidden;
            .way:first-of-type {
                transform: rotate(352deg);
            }
            .way:nth-of-type(2) {
                transform: rotate(346deg);
            }
        }
        div.cont-take-way{
            box-shadow: #7e15d9 6px 49px 15px 64px;
            // box-shadow: #7e15d9 0px 16px 22px 10px;
            width: 100%;
            // background: #7e15d9;
            background: linear-gradient(180deg, rgb(124 21 214) 0%, rgb(126 21 217) 100%);
            position: relative;
            display: flex;
            margin-top: -25px;
        }
        .take-way-btn {
            background-color: white;
            color: black;
            font-weight: 600;
            font-family: "Poppins";
            font-size: 1.7rem;
            align-self: center;
            width: 85%;
            z-index: 2;
            margin: auto;
            height: 6rem;
            border-radius: 17px;
    // filter: drop-shadow(0px 5px 3px rgba(207, 93, 235, 0.88));

        }
    }
    .eclipse {
        width: 32rem;
        max-height: 25rem;
        position: absolute;
        bottom: 0;
        z-index: 5000;
        top: 90%;
    }
}
@media only screen and (min-width:900px) {
    .main-container {
        padding: 12rem 8rem 8rem 8rem;
        display: flex;
        flex-direction: column;
        > span {
            // display: none;
        }
        // display: flex;
        // flex-direction: row;
        .kleever-logo {
            min-height: 18rem !important;
    background-position: center;
    width: 11%;
    max-width: 43rem;
        }
        .cars-div {
            .ways-div {
                display: none !important;

            }
            .car-element {
                width: 100%;
            }
        }
        > img {
            display: none;
        }
    }
}
@media only screen and (min-width:600px) {
    .main-container .kleever-logo {
        min-height: 15rem !important;
        background-position: center;
        // width: 11%;
        max-width: 17rem;
    }
    .main-container .kleever-logo+span {
        font-family: "Poppins";
        font-size: 4.3rem;
        font-weight: 500;
        color: #fff;
    }
    .main-container .kleever-logo+span span {
        font-size: 4.6rem;
        color: #caa4eb;
        font-weight: 500;
    }
    .cars-element {
        min-height: 31rem !important;
        display: none;
    }
  
}

div.for-shdw{
    // background: linear-gradient(180deg, #35006347 100%, #5a00a9 100%, #460182 100%, #31005a 50%);
    background: linear-gradient(180deg, rgb(68 1 126 / 13%) 0, rgb(42 0 78 / 0%) 100%);
    width: 100%;
    height: 5%;
}