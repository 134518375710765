.call-history-page
{
    min-height: 100vh ;
    background-color: #6A01C6;
    .profile-depp-head {
        width: 100%;
        display: flex;
        height: 10.5rem;
        padding: 15px 10px 10px;
        .kleever-log {
            background-image: url('../../assets/images/kleevermainlogo1.png');
            width: 23%;
            background-repeat: no-repeat;
            background-size: contain;
            min-height: 100%;
        }
    }
    .middle-div
    {
    background-color: #ffff;
    min-height: 90vh;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .middle-head{
    width: 100% ;
    min-height: 7rem; 
    max-height: 8rem ;
    display: flex ;
    justify-content: flex-start ;
    align-items: center ;
    .return-control {
        height: 10% ;
        display: flex;
        gap: 1rem;
        align-items: center;
        span{
            font-family: 'Poppins' ;
            font-size: 1.3rem ;
            font-weight: 300; 
        }
    }
    }
    >h2{ 
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 1.7rem;
      color: #6a04c6;;
    }
    .date-filter-part{
        display: flex;
        flex-direction: column;
        padding: 13px;
        height: 14rem;
        gap: 2rem;
        padding-top: 4rem;
        .select-date{
            display: flex;
            width: 100%;
            border: solid 0.5px #50505073;
            border-radius: 8px;
            height: 6rem;
            background-color: white;
            align-items: center;
        // .date-div {
        //     width: 50%;
        //     display: flex;
        //     height: 7rem;
        //     align-items: center;
        //     gap: 7px;
        //     span {
        //         height: 100%;
        //         padding-top: 2.2rem;
        //         align-self: center;
        //         text-align: center;
        //         font-size: 1.8rem;
        //         font-family: 'Poppins',sans-serif;
        //         font-weight: 400;
        //     }
        //     input[type="date"] {
        //         background-color: transparent;
        //         width: 75%;
        //         border: none;
        //         height: 80%;
        //     }
        //     input[type="date"]:first-of-type {
        //         border-right: solid 0.5px #50505073;

        //     }
        //     input[type="date"]::-webkit-calendar-picker-indicator,
        //     input[type="date"]::-webkit-datetime-edit-text,
        //     input[type="date"]::-webkit-inner-spin-button {
        //         display: none;
        //     }
        //     img {
        //         width: 25%;
        //         padding: 11px;
        //         border-radius: 26px;
        //         height: 97%;
        //         object-fit: contain;
        //     }
        // }
        .date-div {
            width: 50%;
            display: flex;
            height: 7rem;
            align-items: center;
            // gap: 7px;
            height: 100% !important;
            span {
                height: fit-content;
                align-self: center;
                text-align: center;
                text-align: center;
                font-size: 1.56rem;
                font-family: 'Poppins',sans-serif;
                font-weight: 400;
            }
            input[type="date"] {
                background-color: transparent;
                width: 66%;
                border: none;
                height: 80%;
            }
            input[type="date"]::-webkit-calendar-picker-indicator
            // input[type="date"]::-webkit-datetime-edit-text,
            {
               opacity: 0;
            //    z-index: -2;
               position: absolute ; 
               width: 10rem;
            // max-height: 0.2px;
               
            }
            input[type="date"]::placeholder {
                // display: none;
            }
            img {
                width: 25%;
                padding: 11px;
                border-radius: 26px;
                height: 97%;
                object-fit: contain;
            }
        }
.date-div:first-of-type{
    border-right: solid 0.5px #505050;
}
      
    }
    }
      .calls-total-div{
          height: 30rem ;
          display: flex;
          flex-direction: column; 
          width: 100%;   
          align-items: center;
          justify-content: center;
          h2{
          color: #6A01C6 !important;
          }
        }
    }

}