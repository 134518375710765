.pay-drawer {
    .MuiDrawer-paperAnchorLeft{
        height: 60% !important;
        width: 100%;
        top: unset !important;
        border-radius: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        .payement-container {
            display: flex;
            flex-direction: column;
            padding: 20px 10px;
            gap: 2.5rem;
            align-items: center;
            height: 100%;
            h3 {
                font-family: 'Poppins';
                font-size: 1.8rem;
            }
            .final-btn{
                height: 5rem ;
                font-family: 'Poppins';
                font-size: 1.5rem ;
                font-weight: 500;
                max-width: 700px;
                border-radius: 25px;
            }
            .pay-type {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 3%;
                max-width: 700px;
        
                span{
                    font-family: 'Poppins',sans-serif ;
                    font-size: 1rem ;
                    color: #CB91FE;
                }
                p{
                    margin-bottom: unset !important;
                    font-family: 'Poppins',sans-serif;
                    font-weight: 400;
                    color: gray;
                    font-size: 2rem !important;
                }
             
                .card-types {
                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;
                    #cards-par {
                        margin-bottom: unset !important;
                        font-family: 'Poppins',sans-serif;
                        font-weight: 300;
                        color: gray;
                        font-size: 2rem !important;
                    }
                    .cards {
                        display: flex;
                        gap: 15px;
                    }
                }
            }

        }
    }
}

.arrowBackPay{    
    position: absolute;
    top: 22px;
    left: 20px;
    width: 25px;
}