input{ 
    padding: 10px; 
}
.fiter-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 180vh;
    padding: 20px;
    gap: 2rem;
    padding-top: 9rem;
    padding-bottom: 10rem;
    max-width: 700px;
    .show-btn {
        background-color: #7f11e0;
        width: 74%;
        color: white;
        font-family: 'Poppins', sans-serif;
        border-radius: 25px;
        height: 58px;
        margin-top: 9rem;
        align-self: center;
    }
    p {
        font-family: 'Poppins', sans-serif;
        color: black;
        font-weight: 500;
        font-size: 1.4rem;
    }
    >p{
        display: flex; 
        gap: 2rem;
        span{
            font-family: 'Poppins' ;
            font-size: 1.1rem ;
            font-weight: 300;
            opacity: 0.6;
        }
    }
    p+span {
        font-size: 1.2rem;
        opacity: 0.6;
    }
    .price-range {
        display: flex;
        width: 100%;
        justify-content: space-between;
        p,
        span {
            font-family: 'Poppins', sans-serif;

        }
    }
    .chauffeur-div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 4rem;
        margin-top: 1rem;
        button {
            width: 40%;
            color: black;
            border: solid 1px rgba(0, 0, 0, 0.265);
            font-family: 'Poppins';
            font-size: 12px;
            height: 3.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        button.selected{
             background-color: #ab4cfe;
             color:#f1f1f1;
        }
    }
    .select-option {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 6rem;
        background-color: #f1f1f1;
        img {
            min-height: 6px !important;
            max-height: 84% !important;
            padding: 5px;
            background-color: transparent !important;
            border-radius: 50% !important;
            width: 17% !important;
        }
        span {
            font-family: 'Poppins', sans-serif;
            color: gray;
            font-weight: 500;
            font-size: 1.6rem;
            text-align: center;
        }
        button {
            height: 35px;
            padding: 6px;
            max-width: 100px;
            background-color: #7f11e0;
        }
    }
    .css-b62m3t-container
    {
        margin-bottom: 2rem;
    }
    .css-1u9des2-indicatorSeparator {
        display: none;
    }
    .css-13cymwt-control {
        border: none;
        min-height: 6rem;
    }
    .css-1hb7zxy-IndicatorsContainer {
        background: #f1f1f1 !important;

    }
    .css-1fdsijx-ValueContainer,.css-hlgwow {
        background: #f1f1f1;
        .css-1jqq78o-placeholder{
            font-family: 'Poppins' !important;
            font-size: 2rem !important;
            width:100% !important; 
            display: flex !important;
            justify-content: center !important;
        }
        .select-option {
            justify-content: flex-start;
            span {
                color: #7f11e0;
                width: 82%;
                font-size: 1.4rem;
                font-family: "Poppins";
                font-weight: 600;
                text-align: center;
            }
            button {
                display: none;
            }
            img {
                height: 70% !important;
    padding: 6px 2px 5px 5px;
    border-radius: 50% !important;
    background-color: white !important;
    border: 1px solid #7f11e0;
    width: 16% !important;
    object-fit: contain;
            }
        }
    }
    .css-1fdsijx-ValueContainer:active, .css-hlgwow:active { 
        border: unset ;
    }
    .css-1nmdiq5-menu {
        background-color: white;
        *:focus , *:active , *:hover , *:visited{
            background-color: unset ;
            
            border: unset ;
        }
        .disabled{
            display: none;
        }
        .css-tr4s17-option,.css-d7l1ni-option
        {
            background-color: white;
        }
        .select-option {
            background-color: white;
            // gap: 10%;
        }
        button {
            height: 35%;
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Poppins';
            font-size: 0.8rem;
            font-family: 200;
            font-weight: 200 ;
            padding: 1px;
            border-radius: 25px;
        }
        img {
            min-height: 80% !important;
            max-height: 90%;
            min-width: 78px ;
            object-fit: cover;
        }
        span {
            font-family: 'Poppins';
            font-size: 1.45rem;
            width: 40% !important;
            font-weight: 300;
            text-align: center;
        }
    }

}
@media  only screen and (min-width:900px) {

   .fiter-container{ 
    width: 70% ;
    align-self: center;
   }
    .css-1fdsijx-ValueContainer,.css-hlgwow,.css-1nmdiq5-menu 
    {
        .select-option {
            img{ 
                max-width: 6% !important;
            }
        }
    }
}